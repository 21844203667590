'use strict';

var cov_2icf2vc567 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.route.js',
        hash = 'b976bfe9b41ad495778fef6a83276286c97c9570',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.route.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 18,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 24
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 10
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 18,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'appRun',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 36
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 9
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2icf2vc567.s[0]++;
(function () {
    'use strict';

    cov_2icf2vc567.f[0]++;
    cov_2icf2vc567.s[1]++;
    angular.module('app.notfound').config(appRun);

    /* @ngInject */
    function appRun($stateProvider) {
        cov_2icf2vc567.f[1]++;
        cov_2icf2vc567.s[2]++;

        $stateProvider.state('notfound', {
            templateUrl: 'app/components/notfound/views/notfound.html',
            controller: 'NotFoundController',
            controllerAs: 'vm',
            url: '/notfound'
        });
    }
})();