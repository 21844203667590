'use strict';

var cov_kjxieu6f8 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/newReportModal/newReportModal.js',
        hash = 'c9f408c4d40d12bd5af2a98f55913f8e3dc4e432',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/newReportModal/newReportModal.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 38,
                    column: 12
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 47
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 15
                }
            },
            '4': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 11
                }
            },
            '5': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 10
                }
            },
            '6': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 29,
                    column: 31
                }
            },
            '7': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 10
                }
            },
            '8': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 15
                }
            },
            '9': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 34,
                    column: 39
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 38,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'NewReportModal',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 39
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 23
                    },
                    end: {
                        line: 10,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 35
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: 'NewReportModalController',
                decl: {
                    start: {
                        line: 22,
                        column: 13
                    },
                    end: {
                        line: 22,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 71
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 28,
                        column: 29
                    },
                    end: {
                        line: 28,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 41
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                },
                line: 28
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 32,
                        column: 30
                    },
                    end: {
                        line: 32,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 42
                    },
                    end: {
                        line: 36,
                        column: 9
                    }
                },
                line: 32
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 33,
                        column: 43
                    },
                    end: {
                        line: 33,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 61
                    },
                    end: {
                        line: 35,
                        column: 13
                    }
                },
                line: 33
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_kjxieu6f8.s[0]++;
(function () {
    'use strict';

    cov_kjxieu6f8.f[0]++;
    cov_kjxieu6f8.s[1]++;
    angular.module('app').service('NewReportModal', NewReportModal);

    /* @ngInject */
    function NewReportModal($mdDialog) {
        cov_kjxieu6f8.f[1]++;
        cov_kjxieu6f8.s[2]++;

        this.display = function () {
            cov_kjxieu6f8.f[2]++;
            cov_kjxieu6f8.s[3]++;

            return $mdDialog.show({
                templateUrl: 'app/components/newReportModal/newReportModal.html',
                controller: NewReportModalController,
                controllerAs: '$ctrl',
                clickOutsideToClose: true,
                escapeToClose: true
            });
        };
    }

    /* @ngInject */
    function NewReportModalController($scope, $mdDialog, ReportsTable) {
        cov_kjxieu6f8.f[3]++;
        cov_kjxieu6f8.s[4]++;

        $scope.newReport = ReportsTable.$build({
            start: new Date(),
            end: new Date()
        });

        cov_kjxieu6f8.s[5]++;
        $scope.closeDialog = function () {
            cov_kjxieu6f8.f[4]++;
            cov_kjxieu6f8.s[6]++;

            $mdDialog.cancel();
        };

        cov_kjxieu6f8.s[7]++;
        $scope.createReport = function () {
            cov_kjxieu6f8.f[5]++;
            cov_kjxieu6f8.s[8]++;

            $scope.newReport.$save().$then(function (report) {
                cov_kjxieu6f8.f[6]++;
                cov_kjxieu6f8.s[9]++;

                $mdDialog.hide(report);
            });
        };
    }
})(angular);