'use strict';

var cov_scsw64cyk = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.run.js',
        hash = '986d74fa3c7d407f83fc2bca026029c3e497a5b1',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.run.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 17,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 23
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 32
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 34
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 17,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 42
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 82
                    },
                    end: {
                        line: 11,
                        column: 83
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 95
                    },
                    end: {
                        line: 14,
                        column: 9
                    }
                },
                line: 11
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_scsw64cyk.s[0]++;
(function () {
    'use strict';

    cov_scsw64cyk.f[0]++;
    cov_scsw64cyk.s[1]++;
    angular.module('app.notfound').run(runBlock);

    /** @ngInject */
    function runBlock($rootScope, $state) {
        cov_scsw64cyk.f[1]++;
        cov_scsw64cyk.s[2]++;

        console.log('runned');
        cov_scsw64cyk.s[3]++;
        $rootScope.notfoundStateChangeError = $rootScope.$on('$stateChangeError', function (e) {
            cov_scsw64cyk.f[2]++;
            cov_scsw64cyk.s[4]++;

            console.log('error');
            cov_scsw64cyk.s[5]++;
            $state.go('notfound');
        });
    }
})();