'use strict';

var cov_2dzjgl4ys2 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/users/adminsList/adminsList.js',
        hash = 'ff49db865a969948f3240b95548630d51a88f262',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/users/adminsList/adminsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 34
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 36
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 36
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 10
                }
            },
            '5': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 10
                }
            },
            '6': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 10
                }
            },
            '7': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 44
                }
            },
            '8': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 73
                }
            },
            '9': {
                start: {
                    line: 28,
                    column: 12
                },
                end: {
                    line: 28,
                    column: 39
                }
            },
            '10': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 10
                }
            },
            '11': {
                start: {
                    line: 34,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 43
                }
            },
            '12': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 73
                }
            },
            '13': {
                start: {
                    line: 36,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 39
                }
            },
            '14': {
                start: {
                    line: 41,
                    column: 22
                },
                end: {
                    line: 45,
                    column: 31
                }
            },
            '15': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 11
                }
            },
            '16': {
                start: {
                    line: 48,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 27
                }
            },
            '17': {
                start: {
                    line: 53,
                    column: 0
                },
                end: {
                    line: 62,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 37
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 12,
                        column: 4
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 27
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 27
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 18
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 4
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 21
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                line: 24
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 25,
                        column: 41
                    },
                    end: {
                        line: 25,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 47
                    },
                    end: {
                        line: 29,
                        column: 9
                    }
                },
                line: 25
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 32,
                        column: 4
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 20
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 32
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 33,
                        column: 40
                    },
                    end: {
                        line: 33,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 46
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                line: 33
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 40,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 21
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 40
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 47,
                        column: 42
                    },
                    end: {
                        line: 47,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 54
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 47
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AdminsListController = function () {
    function AdminsListController($mdToast, $mdDialog) {
        _classCallCheck(this, AdminsListController);

        cov_2dzjgl4ys2.f[0]++;
        cov_2dzjgl4ys2.s[0]++;

        this.$mdToast = $mdToast;
        cov_2dzjgl4ys2.s[1]++;
        this.$mdDialog = $mdDialog;
    }

    _createClass(AdminsListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_2dzjgl4ys2.f[1]++;
            cov_2dzjgl4ys2.s[2]++;

            this._prepareNewAccoutant();
            cov_2dzjgl4ys2.s[3]++;
            this._prepareNewMarketeer();
        }
    }, {
        key: '_prepareNewAccoutant',
        value: function _prepareNewAccoutant() {
            cov_2dzjgl4ys2.f[2]++;
            cov_2dzjgl4ys2.s[4]++;

            this.newAccountant = this.accountants.$build({
                type: 'ACCOUNTANT'
            });
        }
    }, {
        key: '_prepareNewMarketeer',
        value: function _prepareNewMarketeer() {
            cov_2dzjgl4ys2.f[3]++;
            cov_2dzjgl4ys2.s[5]++;

            this.newMarketeer = this.marketeers.$build({
                type: 'MARKETEER'
            });
        }
    }, {
        key: 'saveAccountant',
        value: function saveAccountant() {
            var _this = this;

            cov_2dzjgl4ys2.f[4]++;
            cov_2dzjgl4ys2.s[6]++;

            this.newAccountant.$save().$then(function () {
                cov_2dzjgl4ys2.f[5]++;
                cov_2dzjgl4ys2.s[7]++;

                _this.creatingAccountant = false;
                cov_2dzjgl4ys2.s[8]++;
                _this.$mdToast.showSimple('Administrador criado com sucesso!');
                cov_2dzjgl4ys2.s[9]++;
                _this._prepareNewAccoutant();
            });
        }
    }, {
        key: 'saveMarketeer',
        value: function saveMarketeer() {
            var _this2 = this;

            cov_2dzjgl4ys2.f[6]++;
            cov_2dzjgl4ys2.s[10]++;

            this.newMarketeer.$save().$then(function () {
                cov_2dzjgl4ys2.f[7]++;
                cov_2dzjgl4ys2.s[11]++;

                _this2.creatingMarketeer = false;
                cov_2dzjgl4ys2.s[12]++;
                _this2.$mdToast.showSimple('Administrador criado com sucesso!');
                cov_2dzjgl4ys2.s[13]++;
                _this2._prepareNewMarketeer();
            });
        }
    }, {
        key: 'removeUser',
        value: function removeUser(user) {
            cov_2dzjgl4ys2.f[8]++;

            var confirm = (cov_2dzjgl4ys2.s[14]++, this.$mdDialog.confirm().title('Têm certeza que deseja remover esse administrador?').textContent('Essa ação é irreversível').ok('Remover').cancel('Cancelar'));

            cov_2dzjgl4ys2.s[15]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_2dzjgl4ys2.f[9]++;
                cov_2dzjgl4ys2.s[16]++;

                user.$destroy();
            });
        }
    }]);

    return AdminsListController;
}();

cov_2dzjgl4ys2.s[17]++;


angular.module('app').component('adminsList', {
    templateUrl: 'app/components/users/adminsList/adminsList.html',
    controller: AdminsListController,
    bindings: {
        marketeers: '<',
        accountants: '<'
    }
});