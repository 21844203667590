'use strict';

var cov_k9zmydl7u = function () {
    var path = '/home/lobo/repos/coliseu-app/src/routes.js',
        hash = 'a53d9841f1050caa6362a96f545960fc7b5bb029',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/routes.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 26
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 4
                },
                end: {
                    line: 8,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 4
                },
                end: {
                    line: 187,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 35,
                    column: 50
                },
                end: {
                    line: 37,
                    column: 27
                }
            },
            '5': {
                start: {
                    line: 36,
                    column: 20
                },
                end: {
                    line: 36,
                    column: 93
                }
            },
            '6': {
                start: {
                    line: 36,
                    column: 78
                },
                end: {
                    line: 36,
                    column: 82
                }
            },
            '7': {
                start: {
                    line: 44,
                    column: 50
                },
                end: {
                    line: 46,
                    column: 27
                }
            },
            '8': {
                start: {
                    line: 45,
                    column: 20
                },
                end: {
                    line: 45,
                    column: 93
                }
            },
            '9': {
                start: {
                    line: 45,
                    column: 78
                },
                end: {
                    line: 45,
                    column: 82
                }
            },
            '10': {
                start: {
                    line: 77,
                    column: 41
                },
                end: {
                    line: 80,
                    column: 55
                }
            },
            '11': {
                start: {
                    line: 80,
                    column: 38
                },
                end: {
                    line: 80,
                    column: 45
                }
            },
            '12': {
                start: {
                    line: 87,
                    column: 41
                },
                end: {
                    line: 89,
                    column: 55
                }
            },
            '13': {
                start: {
                    line: 89,
                    column: 38
                },
                end: {
                    line: 89,
                    column: 45
                }
            },
            '14': {
                start: {
                    line: 101,
                    column: 54
                },
                end: {
                    line: 101,
                    column: 122
                }
            },
            '15': {
                start: {
                    line: 101,
                    column: 106
                },
                end: {
                    line: 101,
                    column: 112
                }
            },
            '16': {
                start: {
                    line: 108,
                    column: 62
                },
                end: {
                    line: 111,
                    column: 26
                }
            },
            '17': {
                start: {
                    line: 109,
                    column: 32
                },
                end: {
                    line: 109,
                    column: 38
                }
            },
            '18': {
                start: {
                    line: 110,
                    column: 32
                },
                end: {
                    line: 110,
                    column: 53
                }
            },
            '19': {
                start: {
                    line: 140,
                    column: 44
                },
                end: {
                    line: 143,
                    column: 61
                }
            },
            '20': {
                start: {
                    line: 143,
                    column: 41
                },
                end: {
                    line: 143,
                    column: 51
                }
            },
            '21': {
                start: {
                    line: 144,
                    column: 45
                },
                end: {
                    line: 147,
                    column: 63
                }
            },
            '22': {
                start: {
                    line: 147,
                    column: 42
                },
                end: {
                    line: 147,
                    column: 53
                }
            },
            '23': {
                start: {
                    line: 154,
                    column: 41
                },
                end: {
                    line: 154,
                    column: 65
                }
            },
            '24': {
                start: {
                    line: 161,
                    column: 43
                },
                end: {
                    line: 161,
                    column: 63
                }
            },
            '25': {
                start: {
                    line: 178,
                    column: 38
                },
                end: {
                    line: 178,
                    column: 82
                }
            },
            '26': {
                start: {
                    line: 178,
                    column: 68
                },
                end: {
                    line: 178,
                    column: 72
                }
            },
            '27': {
                start: {
                    line: 185,
                    column: 38
                },
                end: {
                    line: 185,
                    column: 82
                }
            },
            '28': {
                start: {
                    line: 185,
                    column: 68
                },
                end: {
                    line: 185,
                    column: 72
                }
            }
        },
        fnMap: {
            '0': {
                name: 'routesConfig',
                decl: {
                    start: {
                        line: 6,
                        column: 9
                    },
                    end: {
                        line: 6,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 77
                    },
                    end: {
                        line: 188,
                        column: 1
                    }
                },
                line: 6
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 35,
                        column: 22
                    },
                    end: {
                        line: 35,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 50
                    },
                    end: {
                        line: 37,
                        column: 27
                    }
                },
                line: 35
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 35,
                        column: 70
                    },
                    end: {
                        line: 35,
                        column: 71
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 76
                    },
                    end: {
                        line: 37,
                        column: 17
                    }
                },
                line: 35
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 36,
                        column: 68
                    },
                    end: {
                        line: 36,
                        column: 69
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 78
                    },
                    end: {
                        line: 36,
                        column: 82
                    }
                },
                line: 36
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 44,
                        column: 22
                    },
                    end: {
                        line: 44,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 50
                    },
                    end: {
                        line: 46,
                        column: 27
                    }
                },
                line: 44
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 44,
                        column: 70
                    },
                    end: {
                        line: 44,
                        column: 71
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 76
                    },
                    end: {
                        line: 46,
                        column: 17
                    }
                },
                line: 44
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 45,
                        column: 68
                    },
                    end: {
                        line: 45,
                        column: 69
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 78
                    },
                    end: {
                        line: 45,
                        column: 82
                    }
                },
                line: 45
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 77,
                        column: 25
                    },
                    end: {
                        line: 77,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 41
                    },
                    end: {
                        line: 80,
                        column: 55
                    }
                },
                line: 77
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 80,
                        column: 25
                    },
                    end: {
                        line: 80,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 38
                    },
                    end: {
                        line: 80,
                        column: 45
                    }
                },
                line: 80
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 87,
                        column: 25
                    },
                    end: {
                        line: 87,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 87,
                        column: 41
                    },
                    end: {
                        line: 89,
                        column: 55
                    }
                },
                line: 87
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 89,
                        column: 25
                    },
                    end: {
                        line: 89,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 89,
                        column: 38
                    },
                    end: {
                        line: 89,
                        column: 45
                    }
                },
                line: 89
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 101,
                        column: 24
                    },
                    end: {
                        line: 101,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 101,
                        column: 54
                    },
                    end: {
                        line: 101,
                        column: 122
                    }
                },
                line: 101
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 101,
                        column: 94
                    },
                    end: {
                        line: 101,
                        column: 95
                    }
                },
                loc: {
                    start: {
                        line: 101,
                        column: 106
                    },
                    end: {
                        line: 101,
                        column: 112
                    }
                },
                line: 101
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 108,
                        column: 24
                    },
                    end: {
                        line: 108,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 108,
                        column: 62
                    },
                    end: {
                        line: 111,
                        column: 26
                    }
                },
                line: 108
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 109,
                        column: 20
                    },
                    end: {
                        line: 109,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 109,
                        column: 32
                    },
                    end: {
                        line: 109,
                        column: 38
                    }
                },
                line: 109
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 110,
                        column: 20
                    },
                    end: {
                        line: 110,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 110,
                        column: 32
                    },
                    end: {
                        line: 110,
                        column: 53
                    }
                },
                line: 110
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 140,
                        column: 28
                    },
                    end: {
                        line: 140,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 140,
                        column: 44
                    },
                    end: {
                        line: 143,
                        column: 61
                    }
                },
                line: 140
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 143,
                        column: 25
                    },
                    end: {
                        line: 143,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 143,
                        column: 41
                    },
                    end: {
                        line: 143,
                        column: 51
                    }
                },
                line: 143
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 144,
                        column: 29
                    },
                    end: {
                        line: 144,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 45
                    },
                    end: {
                        line: 147,
                        column: 63
                    }
                },
                line: 144
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 147,
                        column: 25
                    },
                    end: {
                        line: 147,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 147,
                        column: 42
                    },
                    end: {
                        line: 147,
                        column: 53
                    }
                },
                line: 147
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 154,
                        column: 25
                    },
                    end: {
                        line: 154,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 154,
                        column: 41
                    },
                    end: {
                        line: 154,
                        column: 65
                    }
                },
                line: 154
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 161,
                        column: 25
                    },
                    end: {
                        line: 161,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 161,
                        column: 43
                    },
                    end: {
                        line: 161,
                        column: 63
                    }
                },
                line: 161
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 178,
                        column: 22
                    },
                    end: {
                        line: 178,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 178,
                        column: 38
                    },
                    end: {
                        line: 178,
                        column: 82
                    }
                },
                line: 178
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 178,
                        column: 58
                    },
                    end: {
                        line: 178,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 178,
                        column: 68
                    },
                    end: {
                        line: 178,
                        column: 72
                    }
                },
                line: 178
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 185,
                        column: 22
                    },
                    end: {
                        line: 185,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 185,
                        column: 38
                    },
                    end: {
                        line: 185,
                        column: 82
                    }
                },
                line: 185
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 185,
                        column: 58
                    },
                    end: {
                        line: 185,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 185,
                        column: 68
                    },
                    end: {
                        line: 185,
                        column: 72
                    }
                },
                line: 185
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_k9zmydl7u.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    cov_k9zmydl7u.f[0]++;
    cov_k9zmydl7u.s[1]++;

    $locationProvider.html5Mode(true).hashPrefix('!');
    cov_k9zmydl7u.s[2]++;
    $urlRouterProvider.otherwise('/');

    cov_k9zmydl7u.s[3]++;
    $stateProvider.state('marketeers', {
        url: '/marketing',
        templateUrl: 'app/components/admin/layout.marketeers.html'
    }).state('accountants', {
        url: '/contabilidade',
        templateUrl: 'app/components/admin/layout.accountants.html'
    }).state('login', {
        url: '/login',
        templateUrl: 'app/components/auth/views/auth.login.view.html',
        controller: 'AuthLoginController',
        controllerAs: 'vm'
    }).state('logout', {
        url: '/logout',
        template: '<div></div>',
        controller: 'AuthLogoutController',
        controllerAs: 'vm'
    }).state('profile', {
        url: '/perfil',
        template: '<edit-profile user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user($rootScope, UsersTable) {
                cov_k9zmydl7u.f[1]++;
                cov_k9zmydl7u.s[4]++;
                return $rootScope.me.$then(function () {
                    cov_k9zmydl7u.f[2]++;
                    cov_k9zmydl7u.s[5]++;

                    return UsersTable.$find($rootScope.me.id).$then(function (user) {
                        cov_k9zmydl7u.f[3]++;
                        cov_k9zmydl7u.s[6]++;
                        return user;
                    }).$promise;
                }).$promise;
            }
        }
    }).state('amigos.profile', {
        url: '/perfil',
        template: '<edit-profile user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user($rootScope, UsersTable) {
                cov_k9zmydl7u.f[4]++;
                cov_k9zmydl7u.s[7]++;
                return $rootScope.me.$then(function () {
                    cov_k9zmydl7u.f[5]++;
                    cov_k9zmydl7u.s[8]++;

                    return UsersTable.$find($rootScope.me.id).$then(function (user) {
                        cov_k9zmydl7u.f[6]++;
                        cov_k9zmydl7u.s[9]++;
                        return user;
                    }).$promise;
                }).$promise;
            }
        }
    }).state('forgot', {
        url: '/recuperar_password',
        templateUrl: 'app/components/auth/views/auth.forgot.html',
        controller: 'AuthForgotController',
        controllerAs: 'vm'
    }).state('set_password', {
        url: '/reset_password?token=&email=',
        templateUrl: 'app/components/auth/views/auth.set.password.html',
        controller: 'AuthSetPasswordController',
        controllerAs: 'vm'
    }).state('register', {
        url: '/criar_conta',
        templateUrl: 'app/components/auth/views/auth.register.view.html',
        controller: 'AuthRegisterController',
        controllerAs: 'vm'
    }).state('activate', {
        url: '/activate?token=',
        template: '<div></div>',
        controller: 'AuthActivateController',
        controllerAs: 'vm'
    }).state('marketeers.membersList', {
        url: '/associados',
        template: '<members-list members="$resolve.members" flex layout="column"/>',
        resolve: {
            members: function members(UsersTable) {
                cov_k9zmydl7u.f[7]++;
                cov_k9zmydl7u.s[10]++;
                return UsersTable.$search({
                    type__in: 'GROUP,MEMBER',
                    ordering: 'member_id'
                }).$then(function (members) {
                    cov_k9zmydl7u.f[8]++;
                    cov_k9zmydl7u.s[11]++;
                    return members;
                }).$promise;
            }
        }
    }).state('accountants.membersList', {
        url: '/associados',
        template: '<members-list members="$resolve.members" flex layout="column"/>',
        resolve: {
            members: function members(UsersTable) {
                cov_k9zmydl7u.f[9]++;
                cov_k9zmydl7u.s[12]++;
                return UsersTable.$search({
                    ordering: 'member_id'
                }).$then(function (members) {
                    cov_k9zmydl7u.f[10]++;
                    cov_k9zmydl7u.s[13]++;
                    return members;
                }).$promise;
            }
        }
    }).state('accountants.newMember', {
        url: '/novo-membro?type',
        template: '<new-member-view flex layout="column"/>',
        resolve: {}
    }).state('marketeers.memberDetails', {
        url: '/associado/:id',
        template: '<member-details member="$resolve.member" flex layout="column"/>',
        resolve: {
            member: function member(UsersTable, $stateParams) {
                cov_k9zmydl7u.f[11]++;
                cov_k9zmydl7u.s[14]++;
                return UsersTable.$find($stateParams.id).$then(function (member) {
                    cov_k9zmydl7u.f[12]++;
                    cov_k9zmydl7u.s[15]++;
                    return member;
                }).$promise;
            }
        }
    }).state('accountants.member', {
        url: '/associado/:id',
        component: 'memberView',
        resolve: {
            member: function member(UsersTable, $stateParams, $state) {
                cov_k9zmydl7u.f[13]++;
                cov_k9zmydl7u.s[16]++;
                return UsersTable.$find($stateParams.id).$then(function (member) {
                    cov_k9zmydl7u.f[14]++;
                    cov_k9zmydl7u.s[17]++;
                    return member;
                }, function (member) {
                    cov_k9zmydl7u.f[15]++;
                    cov_k9zmydl7u.s[18]++;
                    return $state.go('notfound');
                }).$promise;
            }
        }
    }).state('accountants.settings', {
        url: '/settings',
        component: 'settingsView',
        resolve: {}
    }).state('accountants.settings.quotas', {
        url: '/quotas-e-joias',
        component: 'quotaSettingsView',
        resolve: {}
    }).state('accountants.member.details', {
        url: '/detalhes',
        template: '<member-details member="$resolve.member" flex layout="column"/>'
    }).state('accountants.member.quotas', {
        url: '/quotas',
        template: '<member-quotas member="$resolve.member" flex layout="column"/>'
    }).state('accountants.member.payments', {
        url: '/pagamentos',
        template: '<member-payments-view member="$resolve.member" flex layout="column"/>'
    }).state('accountants.adminsList', {
        url: '/administradores',
        template: '<admins-list marketeers="$resolve.marketeers" accountants="$resolve.accountants" flex layout="column"/>',
        resolve: {
            marketeers: function marketeers(UsersTable) {
                cov_k9zmydl7u.f[16]++;
                cov_k9zmydl7u.s[19]++;
                return UsersTable.$search({
                    type: 'MARKETEER',
                    page_size: 9999
                }).$then(function (marketeers) {
                    cov_k9zmydl7u.f[17]++;
                    cov_k9zmydl7u.s[20]++;
                    return marketeers;
                }).$promise;
            },
            accountants: function accountants(UsersTable) {
                cov_k9zmydl7u.f[18]++;
                cov_k9zmydl7u.s[21]++;
                return UsersTable.$search({
                    type: 'ACCOUNTANT',
                    page_size: 9999
                }).$then(function (accountants) {
                    cov_k9zmydl7u.f[19]++;
                    cov_k9zmydl7u.s[22]++;
                    return accountants;
                }).$promise;
            }
        }
    }).state('accountants.reportsList', {
        url: '/relatorios',
        template: '<reports-list reports="$resolve.reports" flex layout="column"/>',
        resolve: {
            reports: function reports(ReportsTable) {
                cov_k9zmydl7u.f[20]++;
                cov_k9zmydl7u.s[23]++;
                return ReportsTable.$search({});
            }
        }
    }).state('marketeers.sendEmail', {
        url: '/enviar-email?members',
        template: '<send-email members="$resolve.members" flex layout="column"/>',
        resolve: {
            members: function members($stateParams) {
                cov_k9zmydl7u.f[21]++;
                cov_k9zmydl7u.s[24]++;
                return $stateParams.members;
            }
        }
    }).state('home', {
        url: '/',
        template: '<home-view categories="$resolve.categories" flex layout="column"/>',
        resolve: {}
    }).state('amigos', {
        url: '/amigo',
        templateUrl: 'app/components/amigos/layout.amigos.html',
        requireAmigo: true
    }).state('amigos.dashboard', {
        url: '/dashboard',
        template: '<dashboard layout="column" flex user="$resolve.user"/>',
        resolve: {
            user: function user($rootScope) {
                cov_k9zmydl7u.f[22]++;
                cov_k9zmydl7u.s[25]++;
                return $rootScope.me.$then(function (user) {
                    cov_k9zmydl7u.f[23]++;
                    cov_k9zmydl7u.s[26]++;
                    return user;
                }).$promise;
            }
        }
    }).state('amigos.pay', {
        url: '/associacao',
        template: '<association-state-view layout="column" flex user="$resolve.user"/>',
        resolve: {
            user: function user($rootScope) {
                cov_k9zmydl7u.f[24]++;
                cov_k9zmydl7u.s[27]++;
                return $rootScope.me.$then(function (user) {
                    cov_k9zmydl7u.f[25]++;
                    cov_k9zmydl7u.s[28]++;
                    return user;
                }).$promise;
            }
        }
    });
}