'use strict';

var cov_6jc18zwst = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/footer/footer.js',
        hash = 'bf28539810c4a929a7fb119d0a1383c2cd6ea95e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/footer/footer.js',
        statementMap: {
            '0': {
                start: {
                    line: 5,
                    column: 0
                },
                end: {
                    line: 10,
                    column: 3
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FooterController = function FooterController() {
    _classCallCheck(this, FooterController);
};

cov_6jc18zwst.s[0]++;


angular.module('app').component('footer', {
    templateUrl: 'app/components/footer/footer.html',
    controller: FooterController
});