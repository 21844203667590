'use strict';

var cov_1wj6w31788 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/associationStateView/associationStateView.js',
        hash = 'ee70cc5b0b18b2919bad2a9ca6b8aadb5409d0cd',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/associationStateView/associationStateView.js',
        statementMap: {
            '0': {
                start: {
                    line: 6,
                    column: 0
                },
                end: {
                    line: 11,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 19
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                line: 2
            }
        },
        branchMap: {},
        s: {
            '0': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AssociationStateViewController = function AssociationStateViewController() {
    _classCallCheck(this, AssociationStateViewController);

    cov_1wj6w31788.f[0]++;
};

cov_1wj6w31788.s[0]++;


angular.module('app').component('associationStateView', {
    templateUrl: 'app/components/associationStateView/associationStateView.html',
    controller: AssociationStateViewController
});