'use strict';

var cov_15otv1kedo = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/yearFilterSelect/yearFilterSelect.js',
        hash = '587200182fb01bdc6beaceaf32edb5560279eafe',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/yearFilterSelect/yearFilterSelect.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 27
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 53
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 64
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 23
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 9
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 30
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 48
                }
            },
            '8': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 16
                },
                end: {
                    line: 18,
                    column: 56
                }
            },
            '10': {
                start: {
                    line: 24,
                    column: 0
                },
                end: {
                    line: 34,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 7
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 8,
                        column: 27
                    },
                    end: {
                        line: 8,
                        column: 64
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 8,
                        column: 27
                    },
                    end: {
                        line: 8,
                        column: 43
                    }
                }, {
                    start: {
                        line: 8,
                        column: 47
                    },
                    end: {
                        line: 8,
                        column: 64
                    }
                }],
                line: 8
            },
            '1': {
                loc: {
                    start: {
                        line: 15,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                }, {
                    start: {
                        line: 15,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                }],
                line: 15
            },
            '2': {
                loc: {
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                }, {
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                }],
                line: 17
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var YearFilterSelectController = function () {
    function YearFilterSelectController() {
        _classCallCheck(this, YearFilterSelectController);

        cov_15otv1kedo.f[0]++;
        cov_15otv1kedo.s[0]++;

        this.minYear = 1960;
        cov_15otv1kedo.s[1]++;
        this.maxYear = new Date().getFullYear() + 5;
    }

    _createClass(YearFilterSelectController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_15otv1kedo.f[1]++;
            cov_15otv1kedo.s[2]++;

            this.placeholder = (cov_15otv1kedo.b[0][0]++, this.placeholder) || (cov_15otv1kedo.b[0][1]++, 'Filtrar por ano');
            cov_15otv1kedo.s[3]++;
            this.years = [];

            cov_15otv1kedo.s[4]++;
            for (var i = this.maxYear; i >= this.minYear; i--) {
                cov_15otv1kedo.s[5]++;

                this.years.push(i);
            }

            cov_15otv1kedo.s[6]++;
            if (this.initialYear) {
                cov_15otv1kedo.b[1][0]++;
                cov_15otv1kedo.s[7]++;

                this.filterByYear = this.initialYear;
                cov_15otv1kedo.s[8]++;
                if (this.onChange) {
                    cov_15otv1kedo.b[2][0]++;
                    cov_15otv1kedo.s[9]++;

                    this.onChange({ year: this.filterByYear });
                } else {
                    cov_15otv1kedo.b[2][1]++;
                }
            } else {
                cov_15otv1kedo.b[1][1]++;
            }
        }
    }]);

    return YearFilterSelectController;
}();

cov_15otv1kedo.s[10]++;


angular.module('app').component('yearFilterSelect', {
    templateUrl: 'app/components/yearFilterSelect/yearFilterSelect.html',
    controller: YearFilterSelectController,
    bindings: {
        onChange: '&',
        placeholder: '@',
        initialYear: '<'
    }
});