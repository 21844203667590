'use strict';

var cov_nwla672a3 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.404.controller.js',
        hash = '54eeb3b9c594c48c7a078b5dc6fb071f739e81a3',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.404.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 18,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 17
                },
                end: {
                    line: 10,
                    column: 21
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 19
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 18,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Controller',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 26
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 15,
                        column: 17
                    },
                    end: {
                        line: 15,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 28
                    },
                    end: {
                        line: 16,
                        column: 9
                    }
                },
                line: 15
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_nwla672a3.s[0]++;
(function () {
    'use strict';

    cov_nwla672a3.f[0]++;
    cov_nwla672a3.s[1]++;
    angular.module('app.notfound').controller('NotFoundController', Controller);

    /* @ngInject */
    function Controller() {
        cov_nwla672a3.f[1]++;

        var vm = (cov_nwla672a3.s[2]++, this);

        cov_nwla672a3.s[3]++;
        activate();

        function activate() {
            cov_nwla672a3.f[2]++;
        }
    }
})();