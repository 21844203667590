'use strict';

var cov_p316518e2 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/shared/js/preventLoss.js',
        hash = 'd1c708eef191dbcac230c1f4f4468814f43ea809',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/shared/js/preventLoss.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 42,
                    column: 8
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 33
                },
                end: {
                    line: 5,
                    column: 38
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 36
                },
                end: {
                    line: 6,
                    column: 40
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 36
                },
                end: {
                    line: 7,
                    column: 40
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 10
                }
            },
            '5': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 39
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 15,
                    column: 55
                }
            },
            '9': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 40
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 11
                }
            },
            '11': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 13
                }
            },
            '12': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 28,
                    column: 36
                }
            },
            '13': {
                start: {
                    line: 30,
                    column: 16
                },
                end: {
                    line: 30,
                    column: 45
                }
            },
            '14': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 9
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 13
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 16
                },
                end: {
                    line: 38,
                    column: 45
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 53
                    },
                    end: {
                        line: 3,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 100
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 9,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 49
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 13,
                        column: 39
                    },
                    end: {
                        line: 13,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 109
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 13
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 19,
                        column: 33
                    },
                    end: {
                        line: 19,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 56
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 19
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 35,
                        column: 32
                    },
                    end: {
                        line: 35,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 44
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                },
                line: 35
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 13,
                        column: 49
                    },
                    end: {
                        line: 13,
                        column: 107
                    }
                },
                type: 'default-arg',
                locations: [{
                    start: {
                        line: 13,
                        column: 55
                    },
                    end: {
                        line: 13,
                        column: 107
                    }
                }],
                line: 13
            },
            '1': {
                loc: {
                    start: {
                        line: 27,
                        column: 12
                    },
                    end: {
                        line: 31,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 27,
                        column: 12
                    },
                    end: {
                        line: 31,
                        column: 13
                    }
                }, {
                    start: {
                        line: 27,
                        column: 12
                    },
                    end: {
                        line: 31,
                        column: 13
                    }
                }],
                line: 27
            },
            '2': {
                loc: {
                    start: {
                        line: 27,
                        column: 16
                    },
                    end: {
                        line: 27,
                        column: 69
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 27,
                        column: 16
                    },
                    end: {
                        line: 27,
                        column: 34
                    }
                }, {
                    start: {
                        line: 27,
                        column: 38
                    },
                    end: {
                        line: 27,
                        column: 69
                    }
                }],
                line: 27
            },
            '3': {
                loc: {
                    start: {
                        line: 37,
                        column: 12
                    },
                    end: {
                        line: 39,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 37,
                        column: 12
                    },
                    end: {
                        line: 39,
                        column: 13
                    }
                }, {
                    start: {
                        line: 37,
                        column: 12
                    },
                    end: {
                        line: 39,
                        column: 13
                    }
                }],
                line: 37
            },
            '4': {
                loc: {
                    start: {
                        line: 37,
                        column: 16
                    },
                    end: {
                        line: 37,
                        column: 81
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 37,
                        column: 16
                    },
                    end: {
                        line: 37,
                        column: 34
                    }
                }, {
                    start: {
                        line: 37,
                        column: 38
                    },
                    end: {
                        line: 37,
                        column: 81
                    }
                }],
                line: 37
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_p316518e2.s[0]++;
angular.module('app').run(['$rootScope', '$location', '$transitions', function ($rootScope, $location, $transitions) {
    cov_p316518e2.f[0]++;


    var _preventNavigation = (cov_p316518e2.s[1]++, false);
    var _preventNavigationUrl = (cov_p316518e2.s[2]++, null);
    var _preventNavigationMsg = (cov_p316518e2.s[3]++, null);

    cov_p316518e2.s[4]++;
    $rootScope.allowNavigation = function () {
        cov_p316518e2.f[1]++;
        cov_p316518e2.s[5]++;

        _preventNavigation = false;
    };

    cov_p316518e2.s[6]++;
    $rootScope.preventNavigation = function () {
        var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_p316518e2.b[0][0]++, "You have unsaved changes, do you want to continue?");
        cov_p316518e2.f[2]++;
        cov_p316518e2.s[7]++;

        _preventNavigation = true;
        cov_p316518e2.s[8]++;
        _preventNavigationUrl = $location.absUrl();
        cov_p316518e2.s[9]++;
        _preventNavigationMsg = msg;
    };

    cov_p316518e2.s[10]++;
    $transitions.onStart({}, function ($transition) {
        cov_p316518e2.f[3]++;
        cov_p316518e2.s[11]++;


        // Allow navigation if our old url wasn't where we prevented navigation from
        /*if (_preventNavigationUrl != oldUrl || _preventNavigationUrl == null) {
            $rootScope.allowNavigation();
            return;
        }*/

        if ((cov_p316518e2.b[2][0]++, _preventNavigation) && (cov_p316518e2.b[2][1]++, !confirm(_preventNavigationMsg))) {
            cov_p316518e2.b[1][0]++;
            cov_p316518e2.s[12]++;

            $transition.abort();
        } else {
            cov_p316518e2.b[1][1]++;
            cov_p316518e2.s[13]++;

            $rootScope.allowNavigation();
        }
    });

    // Take care of preventing navigation out of our angular app
    cov_p316518e2.s[14]++;
    window.onbeforeunload = function () {
        cov_p316518e2.f[4]++;
        cov_p316518e2.s[15]++;

        // Use the same data that we've set in our angular app
        if ((cov_p316518e2.b[4][0]++, _preventNavigation) && (cov_p316518e2.b[4][1]++, $location.absUrl() == _preventNavigationUrl)) {
            cov_p316518e2.b[3][0]++;
            cov_p316518e2.s[16]++;

            return _preventNavigationMsg;
        } else {
            cov_p316518e2.b[3][1]++;
        }
    };
}]);