'use strict';

var cov_163mxr9bha = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/shared/js/ORM/tables.orders.factory.js',
        hash = 'a961263ab5605830c925bf3ed192fb5c2900817f',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/shared/js/ORM/tables.orders.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 63,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 48
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 11
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 63,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 9
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_163mxr9bha.s[0]++;
(function () {
    'use strict';

    cov_163mxr9bha.f[0]++;
    cov_163mxr9bha.s[1]++;
    angular.module('app').factory('OrdersTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_163mxr9bha.f[1]++;
        cov_163mxr9bha.s[2]++;


        return restmod.model('/orders').mix('MixinContainable', {
            $config: {
                contains: {
                    paymentIfthen: {
                        type: 'belongsTo',
                        table: 'PaymentsIfthenTable',
                        foreignKey: 'paymentId',
                        filters: {
                            payment_type: 'IFTHEN'
                        }
                    },
                    paymentCash: {
                        type: 'belongsTo',
                        table: 'PaymentsCashTable',
                        foreignKey: 'paymentId',
                        filters: {
                            payment_type: 'CASH'
                        }
                    },
                    paymentType: {
                        type: 'belongsTo',
                        table: 'PaymentTypesTable',
                        foreignKey: 'paymentType'
                    },
                    gems: {
                        type: 'hasMany',
                        table: 'GemsTable',
                        foreignKey: 'order',
                        filters: {
                            page_size: 99999
                        }
                    },
                    quotas: {
                        type: 'hasMany',
                        table: 'QuotasTable',
                        foreignKey: 'order',
                        filters: {
                            page_size: 99999
                        }
                    },
                    donations: {
                        type: 'hasMany',
                        table: 'DonationsTable',
                        foreignKey: 'order',
                        filters: {
                            page_size: 99999
                        }
                    }
                }
            }
        });
    }
})();