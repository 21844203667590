'use strict';

var cov_2r9nqu5dcg = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/users/editProfile/editProfile.js',
        hash = '8bcbd941df4dcfb81846007f9e88241d0a0657e4',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/users/editProfile/editProfile.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 53
                }
            },
            '4': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 62
                }
            },
            '5': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 37
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 11
                }
            },
            '7': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 13
                }
            },
            '8': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 17,
                    column: 19
                }
            },
            '9': {
                start: {
                    line: 13,
                    column: 20
                },
                end: {
                    line: 13,
                    column: 110
                }
            },
            '10': {
                start: {
                    line: 14,
                    column: 20
                },
                end: {
                    line: 14,
                    column: 59
                }
            },
            '11': {
                start: {
                    line: 16,
                    column: 20
                },
                end: {
                    line: 16,
                    column: 94
                }
            },
            '12': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 15
                }
            },
            '14': {
                start: {
                    line: 26,
                    column: 16
                },
                end: {
                    line: 32,
                    column: 19
                }
            },
            '15': {
                start: {
                    line: 29,
                    column: 20
                },
                end: {
                    line: 31,
                    column: 21
                }
            },
            '16': {
                start: {
                    line: 30,
                    column: 24
                },
                end: {
                    line: 30,
                    column: 50
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 9
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 42,
                    column: 15
                }
            },
            '19': {
                start: {
                    line: 44,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 50
                }
            },
            '20': {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 11
                }
            },
            '21': {
                start: {
                    line: 50,
                    column: 12
                },
                end: {
                    line: 54,
                    column: 15
                }
            },
            '22': {
                start: {
                    line: 51,
                    column: 16
                },
                end: {
                    line: 51,
                    column: 94
                }
            },
            '23': {
                start: {
                    line: 53,
                    column: 16
                },
                end: {
                    line: 53,
                    column: 41
                }
            },
            '24': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 11
                }
            },
            '25': {
                start: {
                    line: 60,
                    column: 12
                },
                end: {
                    line: 60,
                    column: 94
                }
            },
            '26': {
                start: {
                    line: 61,
                    column: 12
                },
                end: {
                    line: 61,
                    column: 67
                }
            },
            '27': {
                start: {
                    line: 66,
                    column: 0
                },
                end: {
                    line: 74,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 109
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 43
                    },
                    end: {
                        line: 10,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 49
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 12,
                        column: 50
                    },
                    end: {
                        line: 12,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 56
                    },
                    end: {
                        line: 15,
                        column: 17
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 15,
                        column: 19
                    },
                    end: {
                        line: 15,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 25
                    },
                    end: {
                        line: 17,
                        column: 17
                    }
                },
                line: 15
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 23,
                        column: 4
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 14
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 23
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 24,
                        column: 35
                    },
                    end: {
                        line: 24,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 51
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                },
                line: 24
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 25,
                        column: 31
                    },
                    end: {
                        line: 25,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 45
                    },
                    end: {
                        line: 33,
                        column: 13
                    }
                },
                line: 25
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 28,
                        column: 40
                    },
                    end: {
                        line: 28,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 57
                    },
                    end: {
                        line: 32,
                        column: 17
                    }
                },
                line: 28
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 37,
                        column: 4
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 33
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                line: 37
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 48,
                        column: 4
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 17
                    },
                    end: {
                        line: 56,
                        column: 5
                    }
                },
                line: 48
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 49,
                        column: 133
                    },
                    end: {
                        line: 49,
                        column: 134
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 139
                    },
                    end: {
                        line: 55,
                        column: 9
                    }
                },
                line: 49
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 50,
                        column: 62
                    },
                    end: {
                        line: 50,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 68
                    },
                    end: {
                        line: 52,
                        column: 13
                    }
                },
                line: 50
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 52,
                        column: 15
                    },
                    end: {
                        line: 52,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 21
                    },
                    end: {
                        line: 54,
                        column: 13
                    }
                },
                line: 52
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 58,
                        column: 4
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 17
                    },
                    end: {
                        line: 63,
                        column: 5
                    }
                },
                line: 58
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 59,
                        column: 64
                    },
                    end: {
                        line: 59,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 70
                    },
                    end: {
                        line: 62,
                        column: 9
                    }
                },
                line: 59
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                }, {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 11,
                        column: 16
                    },
                    end: {
                        line: 11,
                        column: 94
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 11,
                        column: 16
                    },
                    end: {
                        line: 11,
                        column: 50
                    }
                }, {
                    start: {
                        line: 11,
                        column: 54
                    },
                    end: {
                        line: 11,
                        column: 94
                    }
                }],
                line: 11
            },
            '2': {
                loc: {
                    start: {
                        line: 29,
                        column: 20
                    },
                    end: {
                        line: 31,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 29,
                        column: 20
                    },
                    end: {
                        line: 31,
                        column: 21
                    }
                }, {
                    start: {
                        line: 29,
                        column: 20
                    },
                    end: {
                        line: 31,
                        column: 21
                    }
                }],
                line: 29
            },
            '3': {
                loc: {
                    start: {
                        line: 38,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 38,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                }, {
                    start: {
                        line: 38,
                        column: 8
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                }],
                line: 38
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EditProfileController = function () {
    function EditProfileController($mdToast, AuthService, $state, $rootScope, $scope, EventCategoriesTable, UserInterestsTable) {
        var _this = this;

        _classCallCheck(this, EditProfileController);

        cov_2r9nqu5dcg.f[0]++;
        cov_2r9nqu5dcg.s[0]++;

        this.$mdToast = $mdToast;
        cov_2r9nqu5dcg.s[1]++;
        this.AuthService = AuthService;
        cov_2r9nqu5dcg.s[2]++;
        this.$state = $state;
        cov_2r9nqu5dcg.s[3]++;
        this.UserInterestsTable = UserInterestsTable;
        cov_2r9nqu5dcg.s[4]++;
        this.eventCategories = EventCategoriesTable.$search();
        cov_2r9nqu5dcg.s[5]++;
        this.$rootScope = $rootScope;

        cov_2r9nqu5dcg.s[6]++;
        $scope.$watch('$ctrl.user.avatar', function () {
            cov_2r9nqu5dcg.f[1]++;
            cov_2r9nqu5dcg.s[7]++;

            if ((cov_2r9nqu5dcg.b[1][0]++, angular.isString(_this.user.avatar)) && (cov_2r9nqu5dcg.b[1][1]++, _this.user.avatar != $rootScope.me.avatar)) {
                cov_2r9nqu5dcg.b[0][0]++;
                cov_2r9nqu5dcg.s[8]++;

                _this.user.$save(['avatar']).$then(function () {
                    cov_2r9nqu5dcg.f[2]++;
                    cov_2r9nqu5dcg.s[9]++;

                    $mdToast.show($mdToast.simple().textContent(gettext('Imagem de perfil foi atualizada!')));
                    cov_2r9nqu5dcg.s[10]++;
                    AuthService.refreshAuthenticatedInfo();
                }, function () {
                    cov_2r9nqu5dcg.f[3]++;
                    cov_2r9nqu5dcg.s[11]++;

                    $mdToast.show($mdToast.simple().textContent(gettext('Ocorreu um erro!')));
                });
            } else {
                cov_2r9nqu5dcg.b[0][1]++;
            }
        });
    }

    _createClass(EditProfileController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            cov_2r9nqu5dcg.f[4]++;
            cov_2r9nqu5dcg.s[12]++;

            this.eventCategories.$then(function (categories) {
                cov_2r9nqu5dcg.f[5]++;
                cov_2r9nqu5dcg.s[13]++;

                categories.forEach(function (category) {
                    cov_2r9nqu5dcg.f[6]++;
                    cov_2r9nqu5dcg.s[14]++;

                    category.$load('userInterests', {
                        user: _this2.$rootScope.me.id
                    }).$userInterests.$then(function (preferences) {
                        cov_2r9nqu5dcg.f[7]++;
                        cov_2r9nqu5dcg.s[15]++;

                        if (preferences.length) {
                            cov_2r9nqu5dcg.b[2][0]++;
                            cov_2r9nqu5dcg.s[16]++;

                            category.isChecked = true;
                        } else {
                            cov_2r9nqu5dcg.b[2][1]++;
                        }
                    });
                });
            });
        }
    }, {
        key: 'changeUserCategory',
        value: function changeUserCategory(category) {
            cov_2r9nqu5dcg.f[8]++;
            cov_2r9nqu5dcg.s[17]++;

            if (category.isChecked) {
                cov_2r9nqu5dcg.b[3][0]++;
                cov_2r9nqu5dcg.s[18]++;

                category.$userInterests.$create({
                    user: this.$rootScope.me.id,
                    'event_category': category.id
                });
            } else {
                cov_2r9nqu5dcg.b[3][1]++;
                cov_2r9nqu5dcg.s[19]++;

                category.$userInterests[0].$destroy();
            }
        }
    }, {
        key: 'changeInfo',
        value: function changeInfo() {
            var _this3 = this;

            cov_2r9nqu5dcg.f[9]++;
            cov_2r9nqu5dcg.s[20]++;

            this.user.$save(['name', 'email', 'address', 'zipCode', 'zip_code', 'location', 'cellphone', 'cc', 'nif', 'birthday']).$then(function () {
                cov_2r9nqu5dcg.f[10]++;
                cov_2r9nqu5dcg.s[21]++;

                _this3.AuthService.refreshAuthenticatedInfo().$then(function () {
                    cov_2r9nqu5dcg.f[11]++;
                    cov_2r9nqu5dcg.s[22]++;

                    _this3.$mdToast.show(_this3.$mdToast.simple().textContent('Perfil actualizado!'));
                }, function () {
                    cov_2r9nqu5dcg.f[12]++;
                    cov_2r9nqu5dcg.s[23]++;

                    _this3.$state.go('logout');
                });
            });
        }
    }, {
        key: 'changePass',
        value: function changePass() {
            var _this4 = this;

            cov_2r9nqu5dcg.f[13]++;
            cov_2r9nqu5dcg.s[24]++;

            this.user.$save(['password', 'confirm_password']).$then(function () {
                cov_2r9nqu5dcg.f[14]++;
                cov_2r9nqu5dcg.s[25]++;

                _this4.$mdToast.show(_this4.$mdToast.simple().textContent('Palavra-passe alterada!'));
                cov_2r9nqu5dcg.s[26]++;
                _this4.user.password = _this4.user.confirm_password = null;
            });
        }
    }]);

    return EditProfileController;
}();

cov_2r9nqu5dcg.s[27]++;


angular.module('app').component('editProfile', {
    templateUrl: 'app/components/users/editProfile/editProfile.html',
    controller: EditProfileController,
    bindings: {
        user: '<'
    }
});