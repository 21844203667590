'use strict';

var cov_1ka04zv660 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/memberPaymentsView/memberPaymentsView.js',
        hash = '36104ef20493f76d9170aa1132758ca719399874',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/memberPaymentsView/memberPaymentsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 9
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 55
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 46
                }
            },
            '3': {
                start: {
                    line: 15,
                    column: 0
                },
                end: {
                    line: 23,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 37
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 25
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 10
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MemberPaymentsViewController = function () {
    function MemberPaymentsViewController(PaymentDetailsModal) {
        _classCallCheck(this, MemberPaymentsViewController);

        cov_1ka04zv660.f[0]++;
        cov_1ka04zv660.s[0]++;

        this.paymentStates = {
            CREATED: 'Em Rascunho', IN_PAYMENT: 'Pendente', PAID: 'Pago', CANCELED: 'Cancelado', INVALID: 'Inválido'
        };

        cov_1ka04zv660.s[1]++;
        this.PaymentDetailsModal = PaymentDetailsModal;
    }

    _createClass(MemberPaymentsViewController, [{
        key: 'showPayment',
        value: function showPayment(payment) {
            cov_1ka04zv660.f[1]++;
            cov_1ka04zv660.s[2]++;

            this.PaymentDetailsModal.show(payment);
        }
    }]);

    return MemberPaymentsViewController;
}();

cov_1ka04zv660.s[3]++;


angular.module('app').component('memberPaymentsView', {
    templateUrl: 'app/components/memberPaymentsView/memberPaymentsView.html',
    controller: MemberPaymentsViewController,
    bindings: {
        member: '<'
    }
});