'use strict';

var cov_22zefez6tq = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/sendEmail/sendEmail.js',
        hash = '3b191a2fb9bda743e86a3ccd07e7f424647ef243',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/sendEmail/sendEmail.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 49
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 45
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 33
                }
            },
            '4': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 11
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 61
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 52
                },
                end: {
                    line: 16,
                    column: 59
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 87
                }
            },
            '9': {
                start: {
                    line: 19,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 53
                }
            },
            '10': {
                start: {
                    line: 24,
                    column: 0
                },
                end: {
                    line: 32,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 63
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 15
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 15
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 16,
                        column: 42
                    },
                    end: {
                        line: 16,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 52
                    },
                    end: {
                        line: 16,
                        column: 59
                    }
                },
                line: 16
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 17,
                        column: 33
                    },
                    end: {
                        line: 17,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 39
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                },
                line: 17
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SendEmailController = function () {
    function SendEmailController(UsersTable, SendemailTable, $state, $mdToast) {
        _classCallCheck(this, SendEmailController);

        cov_22zefez6tq.f[0]++;
        cov_22zefez6tq.s[0]++;

        this.contacts = UsersTable.$collection();
        cov_22zefez6tq.s[1]++;
        this.email = SendemailTable.$build();
        cov_22zefez6tq.s[2]++;
        this.$state = $state;
        cov_22zefez6tq.s[3]++;
        this.$mdToast = $mdToast;
    }

    _createClass(SendEmailController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_22zefez6tq.f[1]++;
            cov_22zefez6tq.s[4]++;

            this.contacts.$refresh({
                'id__in': this.members
            });
        }
    }, {
        key: 'send',
        value: function send() {
            var _this = this;

            cov_22zefez6tq.f[2]++;
            cov_22zefez6tq.s[5]++;

            this.email.to = this.contacts.map(function (user) {
                cov_22zefez6tq.f[3]++;
                cov_22zefez6tq.s[6]++;
                return user.id;
            });
            cov_22zefez6tq.s[7]++;
            this.email.$save().$then(function () {
                cov_22zefez6tq.f[4]++;
                cov_22zefez6tq.s[8]++;

                _this.$mdToast.show(_this.$mdToast.simple().textContent(_this.email.message));
                cov_22zefez6tq.s[9]++;
                _this.$state.go('marketeers.membersList');
            });
        }
    }]);

    return SendEmailController;
}();

cov_22zefez6tq.s[10]++;


angular.module('app').component('sendEmail', {
    templateUrl: 'app/components/sendEmail//sendEmail.html',
    controller: SendEmailController,
    bindings: {
        members: '<'
    }
});