'use strict';

var cov_678y5pbgd = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/shared/js/media/media.run.js',
        hash = 'a05cc37ea0ff1957e2c2a819e80c88cfd4d0926e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/shared/js/media/media.run.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 12,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 3,
                    column: 4
                },
                end: {
                    line: 5,
                    column: 23
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 41
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 12,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 8,
                        column: 13
                    },
                    end: {
                        line: 8,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 48
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                line: 8
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_678y5pbgd.s[0]++;
(function () {
    'use strict';

    cov_678y5pbgd.f[0]++;
    cov_678y5pbgd.s[1]++;
    angular.module('app').run(runBlock);

    /* @ngInject */
    function runBlock($rootScope, MediaService) {
        cov_678y5pbgd.f[1]++;
        cov_678y5pbgd.s[2]++;

        $rootScope.$Media = MediaService;
    }
})();