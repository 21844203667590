'use strict';

var cov_1hutkht3d0 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/generateQuotasInput/generateQuotasInput.js',
        hash = '071d70cf06c6000cedb799c6c594af81ccc8a4b3',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/generateQuotasInput/generateQuotasInput.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 35
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 21
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 38
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 61
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 35
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 55
                }
            },
            '7': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 61
                }
            },
            '8': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 9
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 16
                },
                end: {
                    line: 18,
                    column: 58
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 22
                }
            },
            '11': {
                start: {
                    line: 21,
                    column: 33
                },
                end: {
                    line: 24,
                    column: 17
                }
            },
            '12': {
                start: {
                    line: 25,
                    column: 16
                },
                end: {
                    line: 25,
                    column: 89
                }
            },
            '13': {
                start: {
                    line: 26,
                    column: 16
                },
                end: {
                    line: 26,
                    column: 22
                }
            },
            '14': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 28
                }
            },
            '15': {
                start: {
                    line: 33,
                    column: 24
                },
                end: {
                    line: 33,
                    column: 26
                }
            },
            '16': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 73,
                    column: 10
                }
            },
            '17': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 64,
                    column: 14
                }
            },
            '18': {
                start: {
                    line: 36,
                    column: 31
                },
                end: {
                    line: 41,
                    column: 18
                }
            },
            '19': {
                start: {
                    line: 42,
                    column: 16
                },
                end: {
                    line: 62,
                    column: 18
                }
            },
            '20': {
                start: {
                    line: 42,
                    column: 42
                },
                end: {
                    line: 42,
                    column: 47
                }
            },
            '21': {
                start: {
                    line: 43,
                    column: 33
                },
                end: {
                    line: 47,
                    column: 22
                }
            },
            '22': {
                start: {
                    line: 48,
                    column: 20
                },
                end: {
                    line: 60,
                    column: 22
                }
            },
            '23': {
                start: {
                    line: 49,
                    column: 24
                },
                end: {
                    line: 59,
                    column: 25
                }
            },
            '24': {
                start: {
                    line: 50,
                    column: 43
                },
                end: {
                    line: 50,
                    column: 52
                }
            },
            '25': {
                start: {
                    line: 51,
                    column: 28
                },
                end: {
                    line: 55,
                    column: 29
                }
            },
            '26': {
                start: {
                    line: 52,
                    column: 32
                },
                end: {
                    line: 52,
                    column: 60
                }
            },
            '27': {
                start: {
                    line: 53,
                    column: 32
                },
                end: {
                    line: 53,
                    column: 70
                }
            },
            '28': {
                start: {
                    line: 53,
                    column: 61
                },
                end: {
                    line: 53,
                    column: 69
                }
            },
            '29': {
                start: {
                    line: 54,
                    column: 32
                },
                end: {
                    line: 54,
                    column: 57
                }
            },
            '30': {
                start: {
                    line: 56,
                    column: 28
                },
                end: {
                    line: 56,
                    column: 43
                }
            },
            '31': {
                start: {
                    line: 58,
                    column: 28
                },
                end: {
                    line: 58,
                    column: 40
                }
            },
            '32': {
                start: {
                    line: 61,
                    column: 20
                },
                end: {
                    line: 61,
                    column: 43
                }
            },
            '33': {
                start: {
                    line: 63,
                    column: 16
                },
                end: {
                    line: 63,
                    column: 41
                }
            },
            '34': {
                start: {
                    line: 65,
                    column: 27
                },
                end: {
                    line: 65,
                    column: 65
                }
            },
            '35': {
                start: {
                    line: 65,
                    column: 50
                },
                end: {
                    line: 65,
                    column: 64
                }
            },
            '36': {
                start: {
                    line: 66,
                    column: 12
                },
                end: {
                    line: 72,
                    column: 13
                }
            },
            '37': {
                start: {
                    line: 68,
                    column: 20
                },
                end: {
                    line: 68,
                    column: 58
                }
            },
            '38': {
                start: {
                    line: 69,
                    column: 20
                },
                end: {
                    line: 69,
                    column: 79
                }
            },
            '39': {
                start: {
                    line: 69,
                    column: 48
                },
                end: {
                    line: 69,
                    column: 78
                }
            },
            '40': {
                start: {
                    line: 71,
                    column: 26
                },
                end: {
                    line: 71,
                    column: 56
                }
            },
            '41': {
                start: {
                    line: 77,
                    column: 0
                },
                end: {
                    line: 85,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 76
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 19
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 15
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 11
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                line: 30
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 34,
                        column: 63
                    },
                    end: {
                        line: 34,
                        column: 64
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 74
                    },
                    end: {
                        line: 73,
                        column: 9
                    }
                },
                line: 34
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 35,
                        column: 40
                    },
                    end: {
                        line: 35,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 49
                    },
                    end: {
                        line: 64,
                        column: 13
                    }
                },
                line: 35
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 42,
                        column: 31
                    },
                    end: {
                        line: 42,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 42
                    },
                    end: {
                        line: 42,
                        column: 47
                    }
                },
                line: 42
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 42,
                        column: 49
                    },
                    end: {
                        line: 42,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 55
                    },
                    end: {
                        line: 62,
                        column: 17
                    }
                },
                line: 42
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 48,
                        column: 33
                    },
                    end: {
                        line: 48,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 43
                    },
                    end: {
                        line: 60,
                        column: 21
                    }
                },
                line: 48
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 53,
                        column: 55
                    },
                    end: {
                        line: 53,
                        column: 56
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 61
                    },
                    end: {
                        line: 53,
                        column: 69
                    }
                },
                line: 53
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 65,
                        column: 41
                    },
                    end: {
                        line: 65,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 65,
                        column: 50
                    },
                    end: {
                        line: 65,
                        column: 64
                    }
                },
                line: 65
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 67,
                        column: 16
                    },
                    end: {
                        line: 67,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 26
                    },
                    end: {
                        line: 70,
                        column: 17
                    }
                },
                line: 67
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 69,
                        column: 42
                    },
                    end: {
                        line: 69,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 48
                    },
                    end: {
                        line: 69,
                        column: 78
                    }
                },
                line: 69
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 71,
                        column: 16
                    },
                    end: {
                        line: 71,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 26
                    },
                    end: {
                        line: 71,
                        column: 56
                    }
                },
                line: 71
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 22
                    }
                }, {
                    start: {
                        line: 20,
                        column: 12
                    },
                    end: {
                        line: 26,
                        column: 22
                    }
                }],
                line: 16
            },
            '1': {
                loc: {
                    start: {
                        line: 22,
                        column: 27
                    },
                    end: {
                        line: 22,
                        column: 55
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 22,
                        column: 50
                    },
                    end: {
                        line: 22,
                        column: 51
                    }
                }, {
                    start: {
                        line: 22,
                        column: 54
                    },
                    end: {
                        line: 22,
                        column: 55
                    }
                }],
                line: 22
            },
            '2': {
                loc: {
                    start: {
                        line: 23,
                        column: 25
                    },
                    end: {
                        line: 23,
                        column: 54
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 23,
                        column: 48
                    },
                    end: {
                        line: 23,
                        column: 49
                    }
                }, {
                    start: {
                        line: 23,
                        column: 52
                    },
                    end: {
                        line: 23,
                        column: 54
                    }
                }],
                line: 23
            },
            '3': {
                loc: {
                    start: {
                        line: 49,
                        column: 24
                    },
                    end: {
                        line: 59,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 49,
                        column: 24
                    },
                    end: {
                        line: 59,
                        column: 25
                    }
                }, {
                    start: {
                        line: 49,
                        column: 24
                    },
                    end: {
                        line: 59,
                        column: 25
                    }
                }],
                line: 49
            },
            '4': {
                loc: {
                    start: {
                        line: 51,
                        column: 28
                    },
                    end: {
                        line: 55,
                        column: 29
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 51,
                        column: 28
                    },
                    end: {
                        line: 55,
                        column: 29
                    }
                }, {
                    start: {
                        line: 51,
                        column: 28
                    },
                    end: {
                        line: 55,
                        column: 29
                    }
                }],
                line: 51
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GenerateQuotasInputController = function () {
    function GenerateQuotasInputController(QuotasTable, $q, $mdDialog, PaymentTypesTable, OrdersTable) {
        _classCallCheck(this, GenerateQuotasInputController);

        cov_1hutkht3d0.f[0]++;
        cov_1hutkht3d0.s[0]++;

        this.QuotasTable = QuotasTable;
        cov_1hutkht3d0.s[1]++;
        this.$mdDialog = $mdDialog;
        cov_1hutkht3d0.s[2]++;
        this.$q = $q;
        cov_1hutkht3d0.s[3]++;
        this.OrdersTable = OrdersTable;

        cov_1hutkht3d0.s[4]++;
        this.generateTypes = ['Anual', 'Semestral', 'Mensal'];
        cov_1hutkht3d0.s[5]++;
        this.generateType = 'Anual';
        cov_1hutkht3d0.s[6]++;
        this.paymentTypes = PaymentTypesTable.$search();

        cov_1hutkht3d0.s[7]++;
        this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }

    _createClass(GenerateQuotasInputController, [{
        key: 'selectMonths',
        value: function selectMonths() {
            cov_1hutkht3d0.f[1]++;
            cov_1hutkht3d0.s[8]++;

            switch (this.generateType) {
                case 'Anual':
                    cov_1hutkht3d0.b[0][0]++;
                    cov_1hutkht3d0.s[9]++;

                    this.selectedMonths = this.months.slice(0);
                    cov_1hutkht3d0.s[10]++;
                    break;
                case 'Semestral':
                    cov_1hutkht3d0.b[0][1]++;

                    var slicePoint = (cov_1hutkht3d0.s[11]++, {
                        start: this.semestre == 1 ? (cov_1hutkht3d0.b[1][0]++, 0) : (cov_1hutkht3d0.b[1][1]++, 6),
                        end: this.semestre == 1 ? (cov_1hutkht3d0.b[2][0]++, 6) : (cov_1hutkht3d0.b[2][1]++, 12)
                    });
                    cov_1hutkht3d0.s[12]++;
                    this.selectedMonths = this.months.slice(slicePoint.start, slicePoint.end);
                    cov_1hutkht3d0.s[13]++;
                    break;
            }
        }
    }, {
        key: 'save',
        value: function save() {
            var _this = this;

            cov_1hutkht3d0.f[2]++;
            cov_1hutkht3d0.s[14]++;

            this.loading = true;

            var newQuotas = (cov_1hutkht3d0.s[15]++, []);
            cov_1hutkht3d0.s[16]++;
            this.OrdersTable.$create({ user: this.member.id }).$then(function (payment) {
                cov_1hutkht3d0.f[3]++;
                cov_1hutkht3d0.s[17]++;

                _this.selectedMonths.forEach(function (month) {
                    cov_1hutkht3d0.f[4]++;

                    var newQuota = (cov_1hutkht3d0.s[18]++, _this.QuotasTable.$create({
                        user: _this.member.id,
                        year: _this.year,
                        month: month,
                        order: payment.id
                    }));
                    cov_1hutkht3d0.s[19]++;
                    newQuota.$then(function (quota) {
                        cov_1hutkht3d0.f[5]++;
                        cov_1hutkht3d0.s[20]++;
                        return quota;
                    }, function () {
                        cov_1hutkht3d0.f[6]++;

                        var search = (cov_1hutkht3d0.s[21]++, _this.QuotasTable.$search({
                            user: _this.member.id,
                            year: _this.year,
                            month: month
                        }));
                        cov_1hutkht3d0.s[22]++;
                        search.$then(function (quotas) {
                            cov_1hutkht3d0.f[7]++;
                            cov_1hutkht3d0.s[23]++;

                            if (quotas[0]) {
                                cov_1hutkht3d0.b[3][0]++;

                                var oldQuota = (cov_1hutkht3d0.s[24]++, quotas[0]);
                                cov_1hutkht3d0.s[25]++;
                                if (oldQuota.state == 'PENDING') {
                                    cov_1hutkht3d0.b[4][0]++;
                                    cov_1hutkht3d0.s[26]++;

                                    oldQuota.order = payment.id;
                                    cov_1hutkht3d0.s[27]++;
                                    oldQuota.$save().$then(function () {
                                        cov_1hutkht3d0.f[8]++;
                                        cov_1hutkht3d0.s[28]++;
                                        return oldQuota;
                                    });
                                    cov_1hutkht3d0.s[29]++;
                                    return oldQuota.$promise;
                                } else {
                                    cov_1hutkht3d0.b[4][1]++;
                                }
                                cov_1hutkht3d0.s[30]++;
                                return oldQuota;
                            } else {
                                cov_1hutkht3d0.b[3][1]++;
                                cov_1hutkht3d0.s[31]++;

                                return false;
                            }
                        });
                        cov_1hutkht3d0.s[32]++;
                        return search.$promise;
                    });
                    cov_1hutkht3d0.s[33]++;
                    newQuotas.push(newQuota);
                });
                var promises = (cov_1hutkht3d0.s[34]++, newQuotas.map(function (quota) {
                    cov_1hutkht3d0.f[9]++;
                    cov_1hutkht3d0.s[35]++;
                    return quota.$promise;
                }));
                cov_1hutkht3d0.s[36]++;
                _this.creating = _this.$q.all(promises).then(function (quotas) {
                    cov_1hutkht3d0.f[10]++;
                    cov_1hutkht3d0.s[37]++;

                    payment.paymentType = _this.paymentType;
                    cov_1hutkht3d0.s[38]++;
                    payment.$save().$then(function () {
                        cov_1hutkht3d0.f[11]++;
                        cov_1hutkht3d0.s[39]++;
                        return _this.$mdDialog.hide(newQuotas);
                    });
                }, function (quotas) {
                    cov_1hutkht3d0.f[12]++;
                    cov_1hutkht3d0.s[40]++;
                    return _this.$mdDialog.hide(newQuotas);
                });
            });
        }
    }]);

    return GenerateQuotasInputController;
}();

cov_1hutkht3d0.s[41]++;


angular.module('app').component('generateQuotasInput', {
    templateUrl: 'app/components/generateQuotasInput/generateQuotasInput.html',
    controller: GenerateQuotasInputController,
    bindings: {
        member: '<'
    }
});