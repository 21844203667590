'use strict';

var cov_zhnaovenu = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/monthName/monthName.js',
        hash = '0523f370bd51a9e70997380070d42e2a1b149c41',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/monthName/monthName.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 58
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 46
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 0
                },
                end: {
                    line: 20,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MonthNameController = function () {
    function MonthNameController() {
        _classCallCheck(this, MonthNameController);

        cov_zhnaovenu.f[0]++;
        cov_zhnaovenu.s[0]++;

        this.names = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    }

    _createClass(MonthNameController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_zhnaovenu.f[1]++;
            cov_zhnaovenu.s[1]++;

            this.name = this.names[this.month - 1];
        }
    }]);

    return MonthNameController;
}();

cov_zhnaovenu.s[2]++;


angular.module('app').component('monthName', {
    templateUrl: 'app/components/monthName/monthName.html',
    controller: MonthNameController,
    bindings: {
        month: '<'
    }
});