'use strict';

var cov_1iphnik2yw = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/memberQuotas/memberQuotas.js',
        hash = '56b0a60fd02855ab68d2dd7e6cc41006751bc3f0',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/memberQuotas/memberQuotas.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 39
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 55
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 55
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 20
                }
            },
            '4': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '5': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 53
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 10
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 15,
                    column: 13
                }
            },
            '8': {
                start: {
                    line: 14,
                    column: 16
                },
                end: {
                    line: 14,
                    column: 39
                }
            },
            '9': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 10
                }
            },
            '10': {
                start: {
                    line: 21,
                    column: 27
                },
                end: {
                    line: 21,
                    column: 29
                }
            },
            '11': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 14
                }
            },
            '12': {
                start: {
                    line: 23,
                    column: 16
                },
                end: {
                    line: 23,
                    column: 38
                }
            },
            '13': {
                start: {
                    line: 24,
                    column: 16
                },
                end: {
                    line: 24,
                    column: 53
                }
            },
            '14': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 14
                }
            },
            '15': {
                start: {
                    line: 27,
                    column: 16
                },
                end: {
                    line: 27,
                    column: 41
                }
            },
            '16': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 33,
                    column: 18
                }
            },
            '17': {
                start: {
                    line: 29,
                    column: 20
                },
                end: {
                    line: 32,
                    column: 22
                }
            },
            '18': {
                start: {
                    line: 30,
                    column: 24
                },
                end: {
                    line: 30,
                    column: 48
                }
            },
            '19': {
                start: {
                    line: 31,
                    column: 24
                },
                end: {
                    line: 31,
                    column: 44
                }
            },
            '20': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 10
                }
            },
            '21': {
                start: {
                    line: 40,
                    column: 12
                },
                end: {
                    line: 40,
                    column: 49
                }
            },
            '22': {
                start: {
                    line: 41,
                    column: 12
                },
                end: {
                    line: 41,
                    column: 42
                }
            },
            '23': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 10
                }
            },
            '24': {
                start: {
                    line: 47,
                    column: 12
                },
                end: {
                    line: 47,
                    column: 43
                }
            },
            '25': {
                start: {
                    line: 52,
                    column: 0
                },
                end: {
                    line: 60,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 94
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 16
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                line: 11
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 12,
                        column: 36
                    },
                    end: {
                        line: 12,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 45
                    },
                    end: {
                        line: 16,
                        column: 9
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 19,
                        column: 4
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 26
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                line: 19
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 20,
                        column: 83
                    },
                    end: {
                        line: 20,
                        column: 84
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 92
                    },
                    end: {
                        line: 35,
                        column: 9
                    }
                },
                line: 20
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 22,
                        column: 40
                    },
                    end: {
                        line: 22,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 49
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                line: 22
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 26,
                        column: 39
                    },
                    end: {
                        line: 26,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 45
                    },
                    end: {
                        line: 34,
                        column: 13
                    }
                },
                line: 26
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 28,
                        column: 36
                    },
                    end: {
                        line: 28,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 47
                    },
                    end: {
                        line: 33,
                        column: 17
                    }
                },
                line: 28
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 29,
                        column: 48
                    },
                    end: {
                        line: 29,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 57
                    },
                    end: {
                        line: 32,
                        column: 21
                    }
                },
                line: 29
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 38,
                        column: 4
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 21
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                line: 38
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 39,
                        column: 56
                    },
                    end: {
                        line: 39,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 66
                    },
                    end: {
                        line: 42,
                        column: 9
                    }
                },
                line: 39
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 45,
                        column: 4
                    },
                    end: {
                        line: 45,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 25
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 45
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 46,
                        column: 52
                    },
                    end: {
                        line: 46,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 60
                    },
                    end: {
                        line: 48,
                        column: 9
                    }
                },
                line: 46
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                }, {
                    start: {
                        line: 13,
                        column: 12
                    },
                    end: {
                        line: 15,
                        column: 13
                    }
                }],
                line: 13
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MemberQuotasController = function () {
    function MemberQuotasController(OrdersTable, GenerateQuotasModal, PaymentTypesTable, $q, PaymentDetailsModal) {
        _classCallCheck(this, MemberQuotasController);

        cov_1iphnik2yw.f[0]++;
        cov_1iphnik2yw.s[0]++;

        this.OrdersTable = OrdersTable;
        cov_1iphnik2yw.s[1]++;
        this.GenerateQuotasModal = GenerateQuotasModal;
        cov_1iphnik2yw.s[2]++;
        this.paymentTypes = PaymentTypesTable.$search();
        cov_1iphnik2yw.s[3]++;
        this.$q = $q;
        cov_1iphnik2yw.s[4]++;
        this.PaymentDetailsModal = PaymentDetailsModal;
        cov_1iphnik2yw.s[5]++;
        this.currentYear = new Date().getFullYear();
    }

    _createClass(MemberQuotasController, [{
        key: 'selectAll',
        value: function selectAll() {
            cov_1iphnik2yw.f[1]++;
            cov_1iphnik2yw.s[6]++;

            this.member.$quotas.forEach(function (quota) {
                cov_1iphnik2yw.f[2]++;
                cov_1iphnik2yw.s[7]++;

                if (quota.state == 'PENDING') {
                    cov_1iphnik2yw.b[0][0]++;
                    cov_1iphnik2yw.s[8]++;

                    quota.isSelected = true;
                } else {
                    cov_1iphnik2yw.b[0][1]++;
                }
            });
        }
    }, {
        key: 'generatePayment',
        value: function generatePayment(type) {
            var _this = this;

            cov_1iphnik2yw.f[3]++;
            cov_1iphnik2yw.s[9]++;

            this.OrdersTable.$create({ user: this.member.id, payment_type: type }).$then(function (order) {
                cov_1iphnik2yw.f[4]++;

                var requests = (cov_1iphnik2yw.s[10]++, []);
                cov_1iphnik2yw.s[11]++;
                _this.selectedQuotas.forEach(function (quota) {
                    cov_1iphnik2yw.f[5]++;
                    cov_1iphnik2yw.s[12]++;

                    quota.order = order.id;
                    cov_1iphnik2yw.s[13]++;
                    requests.push(quota.$save().$promise);
                });
                cov_1iphnik2yw.s[14]++;
                _this.$q.all(requests).then(function () {
                    cov_1iphnik2yw.f[6]++;
                    cov_1iphnik2yw.s[15]++;

                    order.paymentType = type;
                    cov_1iphnik2yw.s[16]++;
                    order.$save().$then(function (order) {
                        cov_1iphnik2yw.f[7]++;
                        cov_1iphnik2yw.s[17]++;

                        _this.selectedQuotas.forEach(function (quota) {
                            cov_1iphnik2yw.f[8]++;
                            cov_1iphnik2yw.s[18]++;

                            quota.isSelected = false;
                            cov_1iphnik2yw.s[19]++;
                            quota.$order = order;
                        });
                    });
                });
            });
        }
    }, {
        key: 'generateQuotas',
        value: function generateQuotas() {
            var _this2 = this;

            cov_1iphnik2yw.f[9]++;
            cov_1iphnik2yw.s[20]++;

            this.GenerateQuotasModal.show(this.member).then(function (quotas) {
                cov_1iphnik2yw.f[10]++;
                cov_1iphnik2yw.s[21]++;

                console.log('created quotas', quotas);
                cov_1iphnik2yw.s[22]++;
                _this2.member.$quotas.$refresh();
            });
        }
    }, {
        key: 'showPayment',
        value: function showPayment(payment) {
            var _this3 = this;

            cov_1iphnik2yw.f[11]++;
            cov_1iphnik2yw.s[23]++;

            this.PaymentDetailsModal.show(payment).then(function (data) {
                cov_1iphnik2yw.f[12]++;
                cov_1iphnik2yw.s[24]++;

                _this3.member.$quotas.$refresh();
            });
        }
    }]);

    return MemberQuotasController;
}();

cov_1iphnik2yw.s[25]++;


angular.module('app').component('memberQuotas', {
    templateUrl: 'app/components/memberQuotas/memberQuotas.html',
    controller: MemberQuotasController,
    bindings: {
        member: '<'
    }
});