'use strict';

var cov_1b056emtsi = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/shared/js/modalServices/generateQuotas.service.js',
        hash = '9d87256155dda33754ef8d24f0f94ac4b8006b2d',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/shared/js/modalServices/generateQuotas.service.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 34,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 49
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 24
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 27
                },
                end: {
                    line: 13,
                    column: 57
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 15
                }
            },
            '5': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 31
                }
            },
            '6': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 29
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 34,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Service',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 45
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'show',
                decl: {
                    start: {
                        line: 12,
                        column: 17
                    },
                    end: {
                        line: 12,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 30
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 12
            },
            '3': {
                name: 'DialogController',
                decl: {
                    start: {
                        line: 28,
                        column: 13
                    },
                    end: {
                        line: 28,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 57
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 28
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 30,
                        column: 29
                    },
                    end: {
                        line: 30,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 41
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 30
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1b056emtsi.s[0]++;
(function () {
    'use strict';

    cov_1b056emtsi.f[0]++;
    cov_1b056emtsi.s[1]++;
    angular.module('app').service('GenerateQuotasModal', Service);

    /* @ngInject */
    function Service($mdDialog, QuotasTable) {
        cov_1b056emtsi.f[1]++;
        cov_1b056emtsi.s[2]++;

        this.show = show;

        function show(member) {
            cov_1b056emtsi.f[2]++;

            var parentEl = (cov_1b056emtsi.s[3]++, angular.element(document.body));
            cov_1b056emtsi.s[4]++;
            return $mdDialog.show({
                parent: parentEl,
                template: '<md-dialog aria-label="List dialog" style="min-width: 400px">' + '  <md-dialog-content>' + '    <generate-quotas-input member="member"/>' + '  </md-dialog-content>' + '</md-dialog>',
                locals: { member: member },
                controller: DialogController
            });
        }
    }

    function DialogController($scope, $mdDialog, member) {
        cov_1b056emtsi.f[3]++;
        cov_1b056emtsi.s[5]++;

        $scope.member = member;
        cov_1b056emtsi.s[6]++;
        $scope.closeDialog = function () {
            cov_1b056emtsi.f[4]++;
            cov_1b056emtsi.s[7]++;

            $mdDialog.hide();
        };
    }
})();