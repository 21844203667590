'use strict';

var cov_12degubr2c = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/monthSelector/monthSelector.js',
        hash = 'f96ea3f9fb23235a6372b58cf7005cd50456f863',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/monthSelector/monthSelector.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 62
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 64
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 0
                },
                end: {
                    line: 21,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 4
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 14
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 6
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 7,
                        column: 27
                    },
                    end: {
                        line: 7,
                        column: 64
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 7,
                        column: 27
                    },
                    end: {
                        line: 7,
                        column: 43
                    }
                }, {
                    start: {
                        line: 7,
                        column: 47
                    },
                    end: {
                        line: 7,
                        column: 64
                    }
                }],
                line: 7
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MonthSelectorController = function () {
    function MonthSelectorController() {
        _classCallCheck(this, MonthSelectorController);

        cov_12degubr2c.f[0]++;
        cov_12degubr2c.s[0]++;

        this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }

    _createClass(MonthSelectorController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_12degubr2c.f[1]++;
            cov_12degubr2c.s[1]++;

            this.placeholder = (cov_12degubr2c.b[0][0]++, this.placeholder) || (cov_12degubr2c.b[0][1]++, 'Filtrar por ano');
        }
    }]);

    return MonthSelectorController;
}();

cov_12degubr2c.s[2]++;


angular.module('app').component('monthSelector', {
    templateUrl: 'app/components/monthSelector/monthSelector.html',
    controller: MonthSelectorController,
    bindings: {
        onChange: '&',
        placeholder: '@',
        selectedMonths: '='
    }
});