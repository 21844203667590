'use strict';

var cov_njsmiiuci = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/auth/auth.set.password.controller.js',
        hash = '66ccdb2cabdd85e2af62301d8c8eca0e56950f1c',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/auth/auth.set.password.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 50,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 76
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 17
                },
                end: {
                    line: 10,
                    column: 21
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 30
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 37
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 19
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 12
                },
                end: {
                    line: 21,
                    column: 13
                }
            },
            '7': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 35
                }
            },
            '8': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 47,
                    column: 15
                }
            },
            '9': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 38,
                    column: 18
                }
            },
            '10': {
                start: {
                    line: 39,
                    column: 16
                },
                end: {
                    line: 39,
                    column: 35
                }
            },
            '11': {
                start: {
                    line: 41,
                    column: 16
                },
                end: {
                    line: 45,
                    column: 18
                }
            },
            '12': {
                start: {
                    line: 46,
                    column: 16
                },
                end: {
                    line: 46,
                    column: 42
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 50,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'AuthSetPasswordController',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 91
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 18,
                        column: 17
                    },
                    end: {
                        line: 18,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 28
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                },
                line: 18
            },
            '3': {
                name: 'setPassword',
                decl: {
                    start: {
                        line: 24,
                        column: 17
                    },
                    end: {
                        line: 24,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 31
                    },
                    end: {
                        line: 48,
                        column: 9
                    }
                },
                line: 24
            },
            '4': {
                name: 'successCallback',
                decl: {
                    start: {
                        line: 33,
                        column: 29
                    },
                    end: {
                        line: 33,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 47
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                },
                line: 33
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 40,
                        column: 15
                    },
                    end: {
                        line: 40,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 27
                    },
                    end: {
                        line: 47,
                        column: 13
                    }
                },
                line: 40
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 19,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 19,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }, {
                    start: {
                        line: 19,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }],
                line: 19
            },
            '1': {
                loc: {
                    start: {
                        line: 19,
                        column: 16
                    },
                    end: {
                        line: 19,
                        column: 58
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 19,
                        column: 16
                    },
                    end: {
                        line: 19,
                        column: 35
                    }
                }, {
                    start: {
                        line: 19,
                        column: 39
                    },
                    end: {
                        line: 19,
                        column: 58
                    }
                }],
                line: 19
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_njsmiiuci.s[0]++;
(function () {
    'use strict';

    cov_njsmiiuci.f[0]++;
    cov_njsmiiuci.s[1]++;
    angular.module('app').controller('AuthSetPasswordController', AuthSetPasswordController);

    /* @ngInject */
    function AuthSetPasswordController($state, $http, AppSettings, $stateParams, $mdToast) {
        cov_njsmiiuci.f[1]++;

        var vm = (cov_njsmiiuci.s[2]++, this);

        cov_njsmiiuci.s[3]++;
        vm.requestStatus = {};

        cov_njsmiiuci.s[4]++;
        vm.setPassword = setPassword;

        cov_njsmiiuci.s[5]++;
        activate();

        function activate() {
            cov_njsmiiuci.f[2]++;
            cov_njsmiiuci.s[6]++;

            if ((cov_njsmiiuci.b[1][0]++, !$stateParams.email) || (cov_njsmiiuci.b[1][1]++, !$stateParams.token)) {
                cov_njsmiiuci.b[0][0]++;
                cov_njsmiiuci.s[7]++;

                $state.go('login');
            } else {
                cov_njsmiiuci.b[0][1]++;
            }
        }

        function setPassword() {
            cov_njsmiiuci.f[3]++;
            cov_njsmiiuci.s[8]++;

            vm.requestStatus = $http({
                method: 'POST',
                url: AppSettings.apiUrl + '/users/set_password/',
                data: {
                    email: $stateParams.email,
                    token: $stateParams.token,
                    password: vm.password
                }
            }).then(function successCallback() {
                cov_njsmiiuci.f[4]++;
                cov_njsmiiuci.s[9]++;

                $mdToast.show($mdToast.simple().textContent('Your password has been changed!').hideDelay(3000));
                cov_njsmiiuci.s[10]++;
                $state.go('login');
            }, function () {
                cov_njsmiiuci.f[5]++;
                cov_njsmiiuci.s[11]++;

                $mdToast.show($mdToast.simple().textContent('An error has ocurred!').hideDelay(3000));
                cov_njsmiiuci.s[12]++;
                $state.go('projectsList');
            });
        }
    }
})();