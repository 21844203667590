'use strict';

var cov_4h8ruyyb7 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/shared/js/ORM/tables.users.factory.js',
        hash = 'ab98678fea8d19ef43bc1b2c7dd0b3b46968dbee',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/shared/js/ORM/tables.users.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 84,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 47
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 82,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 20
                },
                end: {
                    line: 16,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 24
                },
                end: {
                    line: 15,
                    column: 46
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 20
                },
                end: {
                    line: 17,
                    column: 32
                }
            },
            '6': {
                start: {
                    line: 20,
                    column: 20
                },
                end: {
                    line: 20,
                    column: 61
                }
            },
            '7': {
                start: {
                    line: 25,
                    column: 20
                },
                end: {
                    line: 25,
                    column: 39
                }
            },
            '8': {
                start: {
                    line: 26,
                    column: 20
                },
                end: {
                    line: 26,
                    column: 41
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 20
                },
                end: {
                    line: 31,
                    column: 21
                }
            },
            '10': {
                start: {
                    line: 28,
                    column: 24
                },
                end: {
                    line: 28,
                    column: 37
                }
            },
            '11': {
                start: {
                    line: 30,
                    column: 24
                },
                end: {
                    line: 30,
                    column: 36
                }
            },
            '12': {
                start: {
                    line: 36,
                    column: 20
                },
                end: {
                    line: 36,
                    column: 59
                }
            },
            '13': {
                start: {
                    line: 39,
                    column: 20
                },
                end: {
                    line: 39,
                    column: 61
                }
            },
            '14': {
                start: {
                    line: 44,
                    column: 20
                },
                end: {
                    line: 44,
                    column: 59
                }
            },
            '15': {
                start: {
                    line: 47,
                    column: 20
                },
                end: {
                    line: 47,
                    column: 61
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 84,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 83,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 13,
                        column: 24
                    },
                    end: {
                        line: 13,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 41
                    },
                    end: {
                        line: 18,
                        column: 17
                    }
                },
                line: 13
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 19,
                        column: 24
                    },
                    end: {
                        line: 19,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 41
                    },
                    end: {
                        line: 21,
                        column: 17
                    }
                },
                line: 19
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 24
                    },
                    end: {
                        line: 24,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 41
                    },
                    end: {
                        line: 32,
                        column: 17
                    }
                },
                line: 24
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 35,
                        column: 24
                    },
                    end: {
                        line: 35,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 41
                    },
                    end: {
                        line: 37,
                        column: 17
                    }
                },
                line: 35
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 38,
                        column: 24
                    },
                    end: {
                        line: 38,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 41
                    },
                    end: {
                        line: 40,
                        column: 17
                    }
                },
                line: 38
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 43,
                        column: 24
                    },
                    end: {
                        line: 43,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 41
                    },
                    end: {
                        line: 45,
                        column: 17
                    }
                },
                line: 43
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 46,
                        column: 24
                    },
                    end: {
                        line: 46,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 41
                    },
                    end: {
                        line: 48,
                        column: 17
                    }
                },
                line: 46
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 14,
                        column: 20
                    },
                    end: {
                        line: 16,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 14,
                        column: 20
                    },
                    end: {
                        line: 16,
                        column: 21
                    }
                }, {
                    start: {
                        line: 14,
                        column: 20
                    },
                    end: {
                        line: 16,
                        column: 21
                    }
                }],
                line: 14
            },
            '1': {
                loc: {
                    start: {
                        line: 27,
                        column: 20
                    },
                    end: {
                        line: 31,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 27,
                        column: 20
                    },
                    end: {
                        line: 31,
                        column: 21
                    }
                }, {
                    start: {
                        line: 27,
                        column: 20
                    },
                    end: {
                        line: 31,
                        column: 21
                    }
                }],
                line: 27
            },
            '2': {
                loc: {
                    start: {
                        line: 36,
                        column: 27
                    },
                    end: {
                        line: 36,
                        column: 59
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 36,
                        column: 37
                    },
                    end: {
                        line: 36,
                        column: 52
                    }
                }, {
                    start: {
                        line: 36,
                        column: 55
                    },
                    end: {
                        line: 36,
                        column: 59
                    }
                }],
                line: 36
            },
            '3': {
                loc: {
                    start: {
                        line: 44,
                        column: 27
                    },
                    end: {
                        line: 44,
                        column: 59
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 44,
                        column: 37
                    },
                    end: {
                        line: 44,
                        column: 52
                    }
                }, {
                    start: {
                        line: 44,
                        column: 55
                    },
                    end: {
                        line: 44,
                        column: 59
                    }
                }],
                line: 44
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_4h8ruyyb7.s[0]++;
(function () {
    'use strict';

    cov_4h8ruyyb7.f[0]++;
    cov_4h8ruyyb7.s[1]++;
    angular.module('app').factory('UsersTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_4h8ruyyb7.f[1]++;
        cov_4h8ruyyb7.s[2]++;


        return restmod.model('/users').mix('MixinContainable', {
            birthday: {
                decode: function decode(value) {
                    cov_4h8ruyyb7.f[2]++;
                    cov_4h8ruyyb7.s[3]++;

                    if (value) {
                        cov_4h8ruyyb7.b[0][0]++;
                        cov_4h8ruyyb7.s[4]++;

                        return new Date(value);
                    } else {
                        cov_4h8ruyyb7.b[0][1]++;
                    }
                    cov_4h8ruyyb7.s[5]++;
                    return null;
                },
                encode: function encode(value) {
                    cov_4h8ruyyb7.f[3]++;
                    cov_4h8ruyyb7.s[6]++;

                    return moment(value).format('YYYY-MM-DD');
                }
            },
            memberId: {
                encode: function encode(value) {
                    cov_4h8ruyyb7.f[4]++;
                    cov_4h8ruyyb7.s[7]++;

                    value = value + '';
                    cov_4h8ruyyb7.s[8]++;
                    value = value.trim();
                    cov_4h8ruyyb7.s[9]++;
                    if (value) {
                        cov_4h8ruyyb7.b[1][0]++;
                        cov_4h8ruyyb7.s[10]++;

                        return value;
                    } else {
                        cov_4h8ruyyb7.b[1][1]++;
                        cov_4h8ruyyb7.s[11]++;

                        return null;
                    }
                }
            },
            admissionDate: {
                decode: function decode(value) {
                    cov_4h8ruyyb7.f[5]++;
                    cov_4h8ruyyb7.s[12]++;

                    return value ? (cov_4h8ruyyb7.b[2][0]++, new Date(value)) : (cov_4h8ruyyb7.b[2][1]++, null);
                },
                encode: function encode(value) {
                    cov_4h8ruyyb7.f[6]++;
                    cov_4h8ruyyb7.s[13]++;

                    return moment(value).format('YYYY-MM-DD');
                }
            },
            demissionDate: {
                decode: function decode(value) {
                    cov_4h8ruyyb7.f[7]++;
                    cov_4h8ruyyb7.s[14]++;

                    return value ? (cov_4h8ruyyb7.b[3][0]++, new Date(value)) : (cov_4h8ruyyb7.b[3][1]++, null);
                },
                encode: function encode(value) {
                    cov_4h8ruyyb7.f[8]++;
                    cov_4h8ruyyb7.s[15]++;

                    return moment(value).format('YYYY-MM-DD');
                }
            },
            $config: {
                contains: {
                    quotas: {
                        type: 'hasMany',
                        table: 'QuotasTable',
                        foreignKey: 'user',
                        filters: {
                            ordering: '-year,-month',
                            page_size: 25
                        }
                    },
                    gems: {
                        type: 'hasMany',
                        table: 'GemsTable',
                        foreignKey: 'user'
                    },
                    orders: {
                        type: 'hasMany',
                        table: 'OrdersTable',
                        foreignKey: 'user',
                        filters: {
                            ordering: '-created',
                            page_size: 25
                        }
                    },
                    userType: {
                        type: 'belongsTo',
                        table: 'UserTypesTable',
                        foreignKey: 'type'
                    }
                }
            }
        }).mix('DirtyModel');
    }
})();