'use strict';

var cov_1i1d7m3gkg = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/users/memberDetails/memberDetails.js',
        hash = '2c1660a1493eeefba76a04f3d2c760da105293b3',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/users/memberDetails/memberDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 49
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 32
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 34
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 10,
                    column: 16
                },
                end: {
                    line: 10,
                    column: 111
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 12,
                    column: 45
                }
            },
            '7': {
                start: {
                    line: 18,
                    column: 22
                },
                end: {
                    line: 21,
                    column: 31
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 35
                }
            },
            '10': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 15
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 11
                }
            },
            '12': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 15
                }
            },
            '13': {
                start: {
                    line: 33,
                    column: 16
                },
                end: {
                    line: 33,
                    column: 94
                }
            },
            '14': {
                start: {
                    line: 35,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 41
                }
            },
            '15': {
                start: {
                    line: 41,
                    column: 21
                },
                end: {
                    line: 41,
                    column: 41
                }
            },
            '16': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 11
                }
            },
            '17': {
                start: {
                    line: 44,
                    column: 12
                },
                end: {
                    line: 44,
                    column: 90
                }
            },
            '18': {
                start: {
                    line: 49,
                    column: 0
                },
                end: {
                    line: 57,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 73
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 54
                    },
                    end: {
                        line: 8,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 72
                    },
                    end: {
                        line: 14,
                        column: 9
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 17,
                        column: 4
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 20
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 17
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 23,
                        column: 42
                    },
                    end: {
                        line: 23,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 48
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 23
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 28,
                        column: 4
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 22
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 28
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 31,
                        column: 41
                    },
                    end: {
                        line: 31,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 47
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                line: 31
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 32,
                        column: 62
                    },
                    end: {
                        line: 32,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 68
                    },
                    end: {
                        line: 34,
                        column: 13
                    }
                },
                line: 32
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 34,
                        column: 15
                    },
                    end: {
                        line: 34,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 21
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                },
                line: 34
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 40,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 11
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                line: 40
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 43,
                        column: 40
                    },
                    end: {
                        line: 43,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 46
                    },
                    end: {
                        line: 45,
                        column: 9
                    }
                },
                line: 43
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 13,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 13,
                        column: 13
                    }
                }, {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 13,
                        column: 13
                    }
                }],
                line: 9
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MemberDetailsController = function () {
    function MemberDetailsController(UserTypesTable, $mdToast, $mdDialog, $scope, $rootScope) {
        _classCallCheck(this, MemberDetailsController);

        cov_1i1d7m3gkg.f[0]++;
        cov_1i1d7m3gkg.s[0]++;

        this.userTypes = UserTypesTable.$search();
        cov_1i1d7m3gkg.s[1]++;
        this.$mdToast = $mdToast;
        cov_1i1d7m3gkg.s[2]++;
        this.$mdDialog = $mdDialog;

        cov_1i1d7m3gkg.s[3]++;
        $scope.$watch('$ctrl.member.$dirty().length', function (length) {
            cov_1i1d7m3gkg.f[1]++;
            cov_1i1d7m3gkg.s[4]++;

            if (length) {
                cov_1i1d7m3gkg.b[0][0]++;
                cov_1i1d7m3gkg.s[5]++;

                $rootScope.preventNavigation("As alterações não foram gravadas. Têm certeza que deseja sair?");
            } else {
                cov_1i1d7m3gkg.b[0][1]++;
                cov_1i1d7m3gkg.s[6]++;

                $rootScope.allowNavigation();
            }
        });
    }

    _createClass(MemberDetailsController, [{
        key: 'unlockEditing',
        value: function unlockEditing() {
            var _this = this;

            cov_1i1d7m3gkg.f[2]++;

            var confirm = (cov_1i1d7m3gkg.s[7]++, this.$mdDialog.confirm().title('Tem certeza que deseja desbloquear a edição?').ok('Desbloquear').cancel('Cancelar'));

            cov_1i1d7m3gkg.s[8]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_1i1d7m3gkg.f[3]++;
                cov_1i1d7m3gkg.s[9]++;

                _this.isUnlocked = true;
            });
        }
    }, {
        key: 'changeInfo',
        value: function changeInfo(field) {
            var _this2 = this;

            cov_1i1d7m3gkg.f[4]++;
            cov_1i1d7m3gkg.s[10]++;

            return; // Auto save is disabled at this moment

            cov_1i1d7m3gkg.s[11]++;
            this.member.$save([field]).$then(function () {
                cov_1i1d7m3gkg.f[5]++;
                cov_1i1d7m3gkg.s[12]++;

                _this2.AuthService.refreshAuthenticatedInfo().$then(function () {
                    cov_1i1d7m3gkg.f[6]++;
                    cov_1i1d7m3gkg.s[13]++;

                    _this2.$mdToast.show(_this2.$mdToast.simple().textContent('Perfil actualizado!'));
                }, function () {
                    cov_1i1d7m3gkg.f[7]++;
                    cov_1i1d7m3gkg.s[14]++;

                    _this2.$state.go('logout');
                });
            });
        }
    }, {
        key: 'save',
        value: function save() {
            var _this3 = this;

            cov_1i1d7m3gkg.f[8]++;

            var fields = (cov_1i1d7m3gkg.s[15]++, this.member.$dirty());

            cov_1i1d7m3gkg.s[16]++;
            this.member.$save(fields).$then(function () {
                cov_1i1d7m3gkg.f[9]++;
                cov_1i1d7m3gkg.s[17]++;

                _this3.$mdToast.show(_this3.$mdToast.simple().textContent('Perfil actualizado!'));
            });
        }
    }]);

    return MemberDetailsController;
}();

cov_1i1d7m3gkg.s[18]++;


angular.module('app').component('memberDetails', {
    templateUrl: 'app/components/users/memberDetails/memberDetails.html',
    controller: MemberDetailsController,
    bindings: {
        member: '<'
    }
});