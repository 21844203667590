'use strict';

var cov_1zcwi82g4 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.module.js',
        hash = '9f2377db24af4e624b1bec21a59b73503e04aed5',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/notfound/notfound.module.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 6,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 5,
                    column: 36
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 6,
                        column: 1
                    }
                },
                line: 1
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1zcwi82g4.s[0]++;
(function () {
    'use strict';

    cov_1zcwi82g4.f[0]++;
    cov_1zcwi82g4.s[1]++;
    angular.module('app.notfound', []);
})();