'use strict';

var cov_1824uqh44x = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/quotaSettingsView/quotaSettingsView.js',
        hash = '2bf7fbca0058b0e028e33b4ecd00330da33ac6ec',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/quotaSettingsView/quotaSettingsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 54
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 50
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 97
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 32
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 23
                },
                end: {
                    line: 25,
                    column: 9
                }
            },
            '5': {
                start: {
                    line: 18,
                    column: 16
                },
                end: {
                    line: 18,
                    column: 79
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 36
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 16
                },
                end: {
                    line: 22,
                    column: 27
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 21
                },
                end: {
                    line: 24,
                    column: 101
                }
            },
            '10': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 34
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 9
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 16
                },
                end: {
                    line: 33,
                    column: 45
                }
            },
            '13': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 34,
                    column: 44
                }
            },
            '14': {
                start: {
                    line: 40,
                    column: 0
                },
                end: {
                    line: 47,
                    column: 57
                }
            },
            '15': {
                start: {
                    line: 50,
                    column: 4
                },
                end: {
                    line: 50,
                    column: 20
                }
            },
            '16': {
                start: {
                    line: 53,
                    column: 23
                },
                end: {
                    line: 53,
                    column: 53
                }
            },
            '17': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 11
                }
            },
            '18': {
                start: {
                    line: 70,
                    column: 4
                },
                end: {
                    line: 70,
                    column: 23
                }
            },
            '19': {
                start: {
                    line: 71,
                    column: 4
                },
                end: {
                    line: 73,
                    column: 5
                }
            },
            '20': {
                start: {
                    line: 72,
                    column: 8
                },
                end: {
                    line: 72,
                    column: 25
                }
            },
            '21': {
                start: {
                    line: 74,
                    column: 4
                },
                end: {
                    line: 76,
                    column: 5
                }
            },
            '22': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 29
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 84
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 34
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 11
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 22
                    },
                    end: {
                        line: 23,
                        column: 13
                    }
                },
                line: 17
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 24,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 21
                    },
                    end: {
                        line: 24,
                        column: 101
                    }
                },
                line: 24
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 20
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                line: 30
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 32,
                        column: 12
                    },
                    end: {
                        line: 32,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 18
                    },
                    end: {
                        line: 35,
                        column: 13
                    }
                },
                line: 32
            },
            '6': {
                name: 'editQuotaPriceModal',
                decl: {
                    start: {
                        line: 49,
                        column: 9
                    },
                    end: {
                        line: 49,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 40
                    },
                    end: {
                        line: 67,
                        column: 1
                    }
                },
                line: 49
            },
            '7': {
                name: 'show',
                decl: {
                    start: {
                        line: 52,
                        column: 13
                    },
                    end: {
                        line: 52,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 24
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 52
            },
            '8': {
                name: 'editQuotaPriceModalDialog',
                decl: {
                    start: {
                        line: 69,
                        column: 9
                    },
                    end: {
                        line: 69,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 70
                    },
                    end: {
                        line: 77,
                        column: 1
                    }
                },
                line: 69
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 71,
                        column: 25
                    },
                    end: {
                        line: 71,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 37
                    },
                    end: {
                        line: 73,
                        column: 5
                    }
                },
                line: 71
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 74,
                        column: 22
                    },
                    end: {
                        line: 74,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 34
                    },
                    end: {
                        line: 76,
                        column: 5
                    }
                },
                line: 74
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var QuotaSettingsViewController = function () {
    function QuotaSettingsViewController(UserTypesTable, UserTypePricesTable, editQuotaPriceModal, $mdToast) {
        _classCallCheck(this, QuotaSettingsViewController);

        cov_1824uqh44x.f[0]++;
        cov_1824uqh44x.s[0]++;

        this.editQuotaPriceModal = editQuotaPriceModal;

        cov_1824uqh44x.s[1]++;
        this.userTypes = UserTypesTable.$search();
        cov_1824uqh44x.s[2]++;
        this.userTypePrices = UserTypePricesTable.$search({ page_size: 9999, ordering: 'created' });

        cov_1824uqh44x.s[3]++;
        this.$mdToast = $mdToast;
    }

    _createClass(QuotaSettingsViewController, [{
        key: 'changePrice',
        value: function changePrice(type, gem, quota) {
            var _this = this;

            cov_1824uqh44x.f[1]++;

            var newprice = (cov_1824uqh44x.s[4]++, this.userTypePrices.$create({
                user_type: type.id,
                gem_amount: gem,
                quota_amount: quota
            }).$then(function (cont) {
                cov_1824uqh44x.f[2]++;
                cov_1824uqh44x.s[5]++;

                _this.$mdToast.showSimple('Novo periodo de vigência adicionado');
                cov_1824uqh44x.s[6]++;
                _this.userTypes.$refresh();
                cov_1824uqh44x.s[7]++;
                type.editing = false;

                cov_1824uqh44x.s[8]++;
                return cont;
            }, function (err) {
                cov_1824uqh44x.f[3]++;
                cov_1824uqh44x.s[9]++;
                return _this.$mdToast.showSimple('Erro! Não foi possível alterar o periodo de vigência');
            }));

            cov_1824uqh44x.s[10]++;
            newprice.$highlight = true;
        }
    }, {
        key: 'editPrice',
        value: function editPrice(type) {
            var _this2 = this;

            cov_1824uqh44x.f[4]++;
            cov_1824uqh44x.s[11]++;

            this.editQuotaPriceModal.show(type).then(function () {
                cov_1824uqh44x.f[5]++;
                cov_1824uqh44x.s[12]++;

                _this2.memberPrices.$refresh();
                cov_1824uqh44x.s[13]++;
                _this2.groupPrices.$refresh();
            });
        }
    }]);

    return QuotaSettingsViewController;
}();

cov_1824uqh44x.s[14]++;


angular.module('app').component('quotaSettingsView', {
    templateUrl: 'app/components/quotaSettingsView/quotaSettingsView.html',
    controller: QuotaSettingsViewController,
    bindings: {}
}).service('editQuotaPriceModal', editQuotaPriceModal);

function editQuotaPriceModal($mdDialog) {
    cov_1824uqh44x.f[6]++;
    cov_1824uqh44x.s[15]++;

    this.show = show;

    function show(type) {
        cov_1824uqh44x.f[7]++;

        var parentEl = (cov_1824uqh44x.s[16]++, angular.element(document.body));
        cov_1824uqh44x.s[17]++;
        return $mdDialog.show({
            parent: parentEl,
            template: '<md-dialog aria-label="List dialog" style="min-width: 500px">' + '  <md-dialog-content>' + '    <edit-quota-price-view type="type" on-change="onChange()"/>' + '  </md-dialog-content>' + '</md-dialog>',
            locals: { type: type },
            controller: editQuotaPriceModalDialog,
            clickOutsideToClose: true
        });
    }
}

function editQuotaPriceModalDialog($scope, $mdDialog, $mdToast, type) {
    cov_1824uqh44x.f[8]++;
    cov_1824uqh44x.s[18]++;

    $scope.type = type;
    cov_1824uqh44x.s[19]++;
    $scope.closeDialog = function () {
        cov_1824uqh44x.f[9]++;
        cov_1824uqh44x.s[20]++;

        $mdDialog.hide();
    };
    cov_1824uqh44x.s[21]++;
    $scope.onChange = function () {
        cov_1824uqh44x.f[10]++;
        cov_1824uqh44x.s[22]++;

        $scope.closeDialog();
    };
}