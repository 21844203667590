'use strict';

var cov_1i1ymmg5ml = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/reportsList/reportsList.js',
        hash = '08d44b90e226007e5a711614e06145363aeadf53',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/reportsList/reportsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 45
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 35
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 26
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 36
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 29
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 30
                }
            },
            '7': {
                start: {
                    line: 21,
                    column: 24
                },
                end: {
                    line: 25,
                    column: 31
                }
            },
            '8': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 67
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 48
                },
                end: {
                    line: 27,
                    column: 65
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 36
                }
            },
            '12': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 36,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 40
                }
            },
            '14': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 48
                }
            },
            '15': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 41
                }
            },
            '16': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 44
                }
            },
            '17': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 32
                }
            },
            '18': {
                start: {
                    line: 48,
                    column: 0
                },
                end: {
                    line: 56,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 43
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 16
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 43
                    },
                    end: {
                        line: 10,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 49
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 15
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 15
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 26
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 20
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 27,
                        column: 42
                    },
                    end: {
                        line: 27,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 48
                    },
                    end: {
                        line: 27,
                        column: 65
                    }
                },
                line: 27
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 33
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 30
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                }, {
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                }],
                line: 31
            },
            '1': {
                loc: {
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                }, {
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                }],
                line: 35
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ReportsListController = function () {
    function ReportsListController(NewReportModal, $mdDialog) {
        _classCallCheck(this, ReportsListController);

        cov_1i1ymmg5ml.f[0]++;
        cov_1i1ymmg5ml.s[0]++;

        this.NewReportModal = NewReportModal;
        cov_1i1ymmg5ml.s[1]++;
        this.$mdDialog = $mdDialog;
        cov_1i1ymmg5ml.s[2]++;
        this.filters = {};
    }

    _createClass(ReportsListController, [{
        key: 'newReport',
        value: function newReport() {
            var _this = this;

            cov_1i1ymmg5ml.f[1]++;
            cov_1i1ymmg5ml.s[3]++;

            this.NewReportModal.display().then(function () {
                cov_1i1ymmg5ml.f[2]++;
                cov_1i1ymmg5ml.s[4]++;

                _this.reports.$refresh();
            });
        }
    }, {
        key: 'loadMore',
        value: function loadMore() {
            cov_1i1ymmg5ml.f[3]++;
            cov_1i1ymmg5ml.s[5]++;

            this.reports.$page++;
            cov_1i1ymmg5ml.s[6]++;
            this.reports.$fetch();
        }
    }, {
        key: 'destroyReport',
        value: function destroyReport(report) {
            cov_1i1ymmg5ml.f[4]++;

            var confirm = (cov_1i1ymmg5ml.s[7]++, this.$mdDialog.confirm().title('Eliminar Relatório?').textContent('Tem a certeza que quer eliminar este relatório? Esta ação é irreversível.').ok('Sim, quero eliminar.').cancel('Cancelar'));

            cov_1i1ymmg5ml.s[8]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_1i1ymmg5ml.f[5]++;
                cov_1i1ymmg5ml.s[9]++;
                return report.$destroy();
            });
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_1i1ymmg5ml.f[6]++;
            cov_1i1ymmg5ml.s[10]++;

            if (angular.isArray(value)) {
                cov_1i1ymmg5ml.b[0][0]++;
                cov_1i1ymmg5ml.s[11]++;

                value = value.join(',');
            } else {
                cov_1i1ymmg5ml.b[0][1]++;
            }

            cov_1i1ymmg5ml.s[12]++;
            if (value === null) {
                cov_1i1ymmg5ml.b[1][0]++;
                cov_1i1ymmg5ml.s[13]++;

                delete this.filters[filter];
                cov_1i1ymmg5ml.s[14]++;
                delete this.reports.$params[filter];
            } else {
                cov_1i1ymmg5ml.b[1][1]++;
                cov_1i1ymmg5ml.s[15]++;

                this.filters[filter] = value;
            }

            cov_1i1ymmg5ml.s[16]++;
            this.reports.$params = this.filters;
            cov_1i1ymmg5ml.s[17]++;
            this.reports.$refresh();
        }
    }]);

    return ReportsListController;
}();

cov_1i1ymmg5ml.s[18]++;


angular.module('app').component('reportsList', {
    templateUrl: 'app/components/reportsList/reportsList.html',
    controller: ReportsListController,
    bindings: {
        reports: '<'
    }
});