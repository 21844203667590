'use strict';

var cov_yzb77vuup = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/shared/js/reportNames.filter.js',
        hash = '5a14e9c40c7e2b338cec3ad89f738fa29785a1a3',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/shared/js/reportNames.filter.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 19,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 44
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 26
                },
                end: {
                    line: 15,
                    column: 13
                }
            },
            '4': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 19,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'reportNames',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 27
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 32
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 10
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_yzb77vuup.s[0]++;
(function () {
    'use strict';

    cov_yzb77vuup.f[0]++;
    cov_yzb77vuup.s[1]++;
    angular.module('app').filter('reportNames', reportNames);

    /* @ngInject */
    function reportNames() {
        cov_yzb77vuup.f[1]++;
        cov_yzb77vuup.s[2]++;

        return function (value) {
            cov_yzb77vuup.f[2]++;

            var names = (cov_yzb77vuup.s[3]++, {
                NEW_MEMBERS: "Novos Associados",
                EXCLUDED_MEMBERS: "Associados Excluídos",
                DONATIONS: "Donativos"
            });
            cov_yzb77vuup.s[4]++;
            return names[value];
        };
    }
})();