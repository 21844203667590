'use strict';

var cov_8zz4ogum5 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/auth/auth.register.controller.js',
        hash = 'f4e8eb377efdbf82ddcd1020f1df05d34bf5c78c',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/auth/auth.register.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 51,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 66
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 17
                },
                end: {
                    line: 10,
                    column: 21
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 30
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 38
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 32
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 26
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 31
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 27
                }
            },
            '9': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 19
                }
            },
            '10': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 15
                }
            },
            '11': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 28,
                    column: 39
                }
            },
            '12': {
                start: {
                    line: 30,
                    column: 16
                },
                end: {
                    line: 30,
                    column: 100
                }
            },
            '13': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 15
                }
            },
            '14': {
                start: {
                    line: 42,
                    column: 16
                },
                end: {
                    line: 42,
                    column: 33
                }
            },
            '15': {
                start: {
                    line: 43,
                    column: 16
                },
                end: {
                    line: 43,
                    column: 98
                }
            },
            '16': {
                start: {
                    line: 44,
                    column: 16
                },
                end: {
                    line: 44,
                    column: 32
                }
            },
            '17': {
                start: {
                    line: 46,
                    column: 16
                },
                end: {
                    line: 46,
                    column: 99
                }
            },
            '18': {
                start: {
                    line: 47,
                    column: 16
                },
                end: {
                    line: 47,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 51,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'AuthRegisterController',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 78
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 22,
                        column: 17
                    },
                    end: {
                        line: 22,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 28
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                line: 22
            },
            '3': {
                name: 'register',
                decl: {
                    start: {
                        line: 26,
                        column: 17
                    },
                    end: {
                        line: 26,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 28
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 26
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 27,
                        column: 34
                    },
                    end: {
                        line: 27,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 46
                    },
                    end: {
                        line: 29,
                        column: 13
                    }
                },
                line: 27
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 29,
                        column: 15
                    },
                    end: {
                        line: 29,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 27
                    },
                    end: {
                        line: 31,
                        column: 13
                    }
                },
                line: 29
            },
            '6': {
                name: 'resend',
                decl: {
                    start: {
                        line: 34,
                        column: 17
                    },
                    end: {
                        line: 34,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 26
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 34
            },
            '7': {
                name: 'successCallback',
                decl: {
                    start: {
                        line: 41,
                        column: 29
                    },
                    end: {
                        line: 41,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 55
                    },
                    end: {
                        line: 45,
                        column: 13
                    }
                },
                line: 41
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 45,
                        column: 15
                    },
                    end: {
                        line: 45,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 35
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                },
                line: 45
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_8zz4ogum5.s[0]++;
(function () {
    'use strict';

    cov_8zz4ogum5.f[0]++;
    cov_8zz4ogum5.s[1]++;
    angular.module('app').controller('AuthRegisterController', AuthRegisterController);

    /* @ngInject */
    function AuthRegisterController(UsersTable, $mdToast, $http, AppSettings) {
        cov_8zz4ogum5.f[1]++;

        var vm = (cov_8zz4ogum5.s[2]++, this);

        cov_8zz4ogum5.s[3]++;
        vm.registering = true;
        cov_8zz4ogum5.s[4]++;
        vm.user = UsersTable.$build();
        cov_8zz4ogum5.s[5]++;
        vm.resendStatus = false;
        cov_8zz4ogum5.s[6]++;
        vm.resent = false;

        cov_8zz4ogum5.s[7]++;
        vm.register = register;
        cov_8zz4ogum5.s[8]++;
        vm.resend = resend;

        cov_8zz4ogum5.s[9]++;
        activate();

        function activate() {
            cov_8zz4ogum5.f[2]++;
        }

        function register() {
            cov_8zz4ogum5.f[3]++;
            cov_8zz4ogum5.s[10]++;

            vm.user.$save().$then(function () {
                cov_8zz4ogum5.f[4]++;
                cov_8zz4ogum5.s[11]++;

                vm.registering = false;
            }, function () {
                cov_8zz4ogum5.f[5]++;
                cov_8zz4ogum5.s[12]++;

                $mdToast.show($mdToast.simple().textContent('Please fill in all required fields!'));
            });
        }

        function resend() {
            cov_8zz4ogum5.f[6]++;
            cov_8zz4ogum5.s[13]++;

            vm.resendStatus = $http({
                method: 'POST',
                url: AppSettings.apiUrl + '/users/resend_verification_email/',
                data: {
                    email: vm.user.email
                }
            }).then(function successCallback(response) {
                cov_8zz4ogum5.f[7]++;
                cov_8zz4ogum5.s[14]++;

                vm.resent = true;
                cov_8zz4ogum5.s[15]++;
                $mdToast.show($mdToast.simple().textContent('We sent an email to your account!'));
                cov_8zz4ogum5.s[16]++;
                return response;
            }, function (response) {
                cov_8zz4ogum5.f[8]++;
                cov_8zz4ogum5.s[17]++;

                $mdToast.show($mdToast.simple().textContent(response.data['non_field_errors'][0]));
                cov_8zz4ogum5.s[18]++;
                return response;
            });
        }
    }
})();