'use strict';

var cov_iovf372ef = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/users/membersList/membersList.js',
        hash = '51e0259fbcf55d2a05e66f01fd9b75824f6249d5',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/users/membersList/membersList.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 15
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 16
                },
                end: {
                    line: 9,
                    column: 94
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 16
                },
                end: {
                    line: 11,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 31
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 22
                },
                end: {
                    line: 28,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 9
                }
            },
            '8': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 56
                }
            },
            '9': {
                start: {
                    line: 32,
                    column: 16
                },
                end: {
                    line: 32,
                    column: 22
                }
            },
            '10': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 34,
                    column: 56
                }
            },
            '11': {
                start: {
                    line: 35,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 22
                }
            },
            '12': {
                start: {
                    line: 37,
                    column: 16
                },
                end: {
                    line: 37,
                    column: 56
                }
            },
            '13': {
                start: {
                    line: 38,
                    column: 16
                },
                end: {
                    line: 38,
                    column: 22
                }
            },
            '14': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 9
                }
            },
            '15': {
                start: {
                    line: 42,
                    column: 12
                },
                end: {
                    line: 42,
                    column: 41
                }
            },
            '16': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 9
                }
            },
            '17': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 46,
                    column: 45
                }
            },
            '18': {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 9
                }
            },
            '19': {
                start: {
                    line: 50,
                    column: 12
                },
                end: {
                    line: 50,
                    column: 43
                }
            },
            '20': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 39
                }
            },
            '21': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 32
                }
            },
            '22': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 29
                }
            },
            '23': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 30
                }
            },
            '24': {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 63,
                    column: 33
                }
            },
            '25': {
                start: {
                    line: 67,
                    column: 8
                },
                end: {
                    line: 67,
                    column: 61
                }
            },
            '26': {
                start: {
                    line: 67,
                    column: 42
                },
                end: {
                    line: 67,
                    column: 49
                }
            },
            '27': {
                start: {
                    line: 71,
                    column: 0
                },
                end: {
                    line: 79,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 27
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 4
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 17
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 7,
                        column: 124
                    },
                    end: {
                        line: 7,
                        column: 125
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 130
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                },
                line: 7
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 8,
                        column: 62
                    },
                    end: {
                        line: 8,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 68
                    },
                    end: {
                        line: 10,
                        column: 13
                    }
                },
                line: 8
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 21
                    },
                    end: {
                        line: 12,
                        column: 13
                    }
                },
                line: 10
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 16,
                        column: 4
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 23
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 16
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 57,
                        column: 4
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 15
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                line: 57
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 62,
                        column: 4
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 14
                    },
                    end: {
                        line: 64,
                        column: 5
                    }
                },
                line: 62
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 66,
                        column: 4
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 17
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                line: 66
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 67,
                        column: 32
                    },
                    end: {
                        line: 67,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 67,
                        column: 42
                    },
                    end: {
                        line: 67,
                        column: 49
                    }
                },
                line: 67
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 29,
                        column: 8
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 30,
                        column: 12
                    },
                    end: {
                        line: 32,
                        column: 22
                    }
                }, {
                    start: {
                        line: 33,
                        column: 12
                    },
                    end: {
                        line: 35,
                        column: 22
                    }
                }, {
                    start: {
                        line: 36,
                        column: 12
                    },
                    end: {
                        line: 38,
                        column: 22
                    }
                }],
                line: 29
            },
            '1': {
                loc: {
                    start: {
                        line: 41,
                        column: 8
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 41,
                        column: 8
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                }, {
                    start: {
                        line: 41,
                        column: 8
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                }],
                line: 41
            },
            '2': {
                loc: {
                    start: {
                        line: 45,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 45,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                }, {
                    start: {
                        line: 45,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                }],
                line: 45
            },
            '3': {
                loc: {
                    start: {
                        line: 49,
                        column: 8
                    },
                    end: {
                        line: 51,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 49,
                        column: 8
                    },
                    end: {
                        line: 51,
                        column: 9
                    }
                }, {
                    start: {
                        line: 49,
                        column: 8
                    },
                    end: {
                        line: 51,
                        column: 9
                    }
                }],
                line: 49
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MembersListController = function () {
    function MembersListController($mdDialog) {
        _classCallCheck(this, MembersListController);

        cov_iovf372ef.f[0]++;
        cov_iovf372ef.s[0]++;

        this.$mdDialog = $mdDialog;
    }

    _createClass(MembersListController, [{
        key: 'changeInfo',
        value: function changeInfo() {
            var _this = this;

            cov_iovf372ef.f[1]++;
            cov_iovf372ef.s[1]++;

            this.member.$save(['name', 'email', 'address', 'zip_code', 'locality', 'cellphone', 'cc', 'nif', 'birthday']).$then(function () {
                cov_iovf372ef.f[2]++;
                cov_iovf372ef.s[2]++;

                _this.AuthService.refreshAuthenticatedInfo().$then(function () {
                    cov_iovf372ef.f[3]++;
                    cov_iovf372ef.s[3]++;

                    _this.$mdToast.show(_this.$mdToast.simple().textContent('Perfil actualizado!'));
                }, function () {
                    cov_iovf372ef.f[4]++;
                    cov_iovf372ef.s[4]++;

                    _this.$state.go('logout');
                });
            });
        }
    }, {
        key: 'updateMembers',
        value: function updateMembers(all) {
            cov_iovf372ef.f[5]++;
            cov_iovf372ef.s[5]++;

            this.members.$page = 1;

            var filters = (cov_iovf372ef.s[6]++, {
                'search': this.memberName,
                'gender': this.memberGender,
                'birthday__gt': this.deBirthday,
                'birthday__lt': this.ateBirthday,
                'admission_date__gt': this.deAdmission,
                'admission_date__lt': this.ateAdmission,
                // 'type__in': 'GROUP,MEMBER',
                'ordering': 'member_id'
            });
            cov_iovf372ef.s[7]++;
            switch (this.associationStateFilter) {
                case 'REGULAR':
                    cov_iovf372ef.b[0][0]++;
                    cov_iovf372ef.s[8]++;

                    filters['quotas_state__in'] = 'REGULAR';
                    cov_iovf372ef.s[9]++;
                    break;
                case 'PENDING':
                    cov_iovf372ef.b[0][1]++;
                    cov_iovf372ef.s[10]++;

                    filters['quotas_state__in'] = 'PENDING';
                    cov_iovf372ef.s[11]++;
                    break;
                case 'UNKNOWN':
                    cov_iovf372ef.b[0][2]++;
                    cov_iovf372ef.s[12]++;

                    filters['quotas_state__in'] = 'UNKNOWN';
                    cov_iovf372ef.s[13]++;
                    break;
            }

            cov_iovf372ef.s[14]++;
            if (this.showOnlyMembersWithNotes) {
                cov_iovf372ef.b[1][0]++;
                cov_iovf372ef.s[15]++;

                filters['notes__isnull'] = 3;
            } else {
                cov_iovf372ef.b[1][1]++;
            }

            cov_iovf372ef.s[16]++;
            if (this.showOnlyMembersWithoutMemberId) {
                cov_iovf372ef.b[2][0]++;
                cov_iovf372ef.s[17]++;

                filters['member_id__isnull'] = 2;
            } else {
                cov_iovf372ef.b[2][1]++;
            }

            cov_iovf372ef.s[18]++;
            if (all) {
                cov_iovf372ef.b[3][0]++;
                cov_iovf372ef.s[19]++;

                filters['page_size'] = 9999999;
            } else {
                cov_iovf372ef.b[3][1]++;
            }

            cov_iovf372ef.s[20]++;
            this.members.$params = filters;
            cov_iovf372ef.s[21]++;
            this.members.$refresh();
        }
    }, {
        key: 'loadMore',
        value: function loadMore() {
            cov_iovf372ef.f[6]++;
            cov_iovf372ef.s[22]++;

            this.members.$page++;
            cov_iovf372ef.s[23]++;
            this.members.$fetch();
        }
    }, {
        key: 'loadAll',
        value: function loadAll() {
            cov_iovf372ef.f[7]++;
            cov_iovf372ef.s[24]++;

            this.updateMembers(true);
        }
    }, {
        key: 'getUserIds',
        value: function getUserIds() {
            cov_iovf372ef.f[8]++;
            cov_iovf372ef.s[25]++;

            return this.members.map(function (user) {
                cov_iovf372ef.f[9]++;
                cov_iovf372ef.s[26]++;
                return user.id;
            }).join(',');
        }
    }]);

    return MembersListController;
}();

cov_iovf372ef.s[27]++;


angular.module('app').component('membersList', {
    templateUrl: 'app/components/users/membersList/membersList.html',
    controller: MembersListController,
    bindings: {
        members: '<'
    }
});