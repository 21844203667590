'use strict';

var cov_14ydsvtniq = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/homeView/homeView.js',
        hash = '97b88b46792c0280812bcd2b5fcf1a1a857a934a',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/homeView/homeView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 43
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 37
                }
            },
            '4': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 76
                }
            },
            '5': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 9
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 21,
                    column: 15
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 17
                }
            },
            '8': {
                start: {
                    line: 14,
                    column: 20
                },
                end: {
                    line: 14,
                    column: 61
                }
            },
            '9': {
                start: {
                    line: 15,
                    column: 23
                },
                end: {
                    line: 20,
                    column: 17
                }
            },
            '10': {
                start: {
                    line: 16,
                    column: 20
                },
                end: {
                    line: 16,
                    column: 62
                }
            },
            '11': {
                start: {
                    line: 19,
                    column: 20
                },
                end: {
                    line: 19,
                    column: 55
                }
            },
            '12': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 25
                }
            },
            '13': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 31
                }
            },
            '14': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 25
                }
            },
            '15': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 31
                }
            },
            '16': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 9
                }
            },
            '17': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 41
                }
            },
            '18': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 19
                }
            },
            '19': {
                start: {
                    line: 44,
                    column: 28
                },
                end: {
                    line: 44,
                    column: 32
                }
            },
            '20': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 11
                }
            },
            '21': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 13
                }
            },
            '22': {
                start: {
                    line: 47,
                    column: 16
                },
                end: {
                    line: 47,
                    column: 41
                }
            },
            '23': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 29
                }
            },
            '24': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 9
                }
            },
            '25': {
                start: {
                    line: 57,
                    column: 12
                },
                end: {
                    line: 64,
                    column: 14
                }
            },
            '26': {
                start: {
                    line: 65,
                    column: 12
                },
                end: {
                    line: 65,
                    column: 19
                }
            },
            '27': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 78,
                    column: 9
                }
            },
            '28': {
                start: {
                    line: 69,
                    column: 12
                },
                end: {
                    line: 76,
                    column: 14
                }
            },
            '29': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 77,
                    column: 19
                }
            },
            '30': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 87,
                    column: 15
                }
            },
            '31': {
                start: {
                    line: 82,
                    column: 16
                },
                end: {
                    line: 82,
                    column: 39
                }
            },
            '32': {
                start: {
                    line: 91,
                    column: 8
                },
                end: {
                    line: 91,
                    column: 90
                }
            },
            '33': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 95,
                    column: 11
                }
            },
            '34': {
                start: {
                    line: 94,
                    column: 12
                },
                end: {
                    line: 94,
                    column: 47
                }
            },
            '35': {
                start: {
                    line: 99,
                    column: 0
                },
                end: {
                    line: 107,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 83
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 12,
                        column: 37
                    },
                    end: {
                        line: 12,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 47
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 21
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 25
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 21
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 30
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 22
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 35
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 25
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                line: 43
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 45,
                        column: 32
                    },
                    end: {
                        line: 45,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 46
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 45
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 55,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 15
                    },
                    end: {
                        line: 88,
                        column: 5
                    }
                },
                line: 55
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 81,
                        column: 12
                    },
                    end: {
                        line: 81,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 22
                    },
                    end: {
                        line: 84,
                        column: 13
                    }
                },
                line: 81
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 85,
                        column: 12
                    },
                    end: {
                        line: 85,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 85,
                        column: 18
                    },
                    end: {
                        line: 87,
                        column: 13
                    }
                },
                line: 85
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 90,
                        column: 4
                    },
                    end: {
                        line: 90,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 90,
                        column: 12
                    },
                    end: {
                        line: 96,
                        column: 5
                    }
                },
                line: 90
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 93,
                        column: 31
                    },
                    end: {
                        line: 93,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 93,
                        column: 37
                    },
                    end: {
                        line: 95,
                        column: 9
                    }
                },
                line: 93
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                }, {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 20,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 20,
                        column: 17
                    }
                }, {
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 20,
                        column: 17
                    }
                }],
                line: 13
            },
            '2': {
                loc: {
                    start: {
                        line: 15,
                        column: 23
                    },
                    end: {
                        line: 20,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 23
                    },
                    end: {
                        line: 20,
                        column: 17
                    }
                }, {
                    start: {
                        line: 15,
                        column: 23
                    },
                    end: {
                        line: 20,
                        column: 17
                    }
                }],
                line: 15
            },
            '3': {
                loc: {
                    start: {
                        line: 36,
                        column: 8
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 36,
                        column: 8
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                }, {
                    start: {
                        line: 36,
                        column: 8
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                }],
                line: 36
            },
            '4': {
                loc: {
                    start: {
                        line: 36,
                        column: 12
                    },
                    end: {
                        line: 36,
                        column: 36
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 36,
                        column: 12
                    },
                    end: {
                        line: 36,
                        column: 22
                    }
                }, {
                    start: {
                        line: 36,
                        column: 26
                    },
                    end: {
                        line: 36,
                        column: 36
                    }
                }],
                line: 36
            },
            '5': {
                loc: {
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                }, {
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                }],
                line: 46
            },
            '6': {
                loc: {
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 66,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 66,
                        column: 9
                    }
                }, {
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 66,
                        column: 9
                    }
                }],
                line: 56
            },
            '7': {
                loc: {
                    start: {
                        line: 68,
                        column: 8
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 68,
                        column: 8
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                }, {
                    start: {
                        line: 68,
                        column: 8
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                }],
                line: 68
            },
            '8': {
                loc: {
                    start: {
                        line: 68,
                        column: 12
                    },
                    end: {
                        line: 68,
                        column: 90
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 68,
                        column: 12
                    },
                    end: {
                        line: 68,
                        column: 28
                    }
                }, {
                    start: {
                        line: 68,
                        column: 33
                    },
                    end: {
                        line: 68,
                        column: 59
                    }
                }, {
                    start: {
                        line: 68,
                        column: 63
                    },
                    end: {
                        line: 68,
                        column: 89
                    }
                }],
                line: 68
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HomeViewController = function () {
    function HomeViewController(UsersTable, AuthService, $state, $rootScope, EventCategoriesTable) {
        _classCallCheck(this, HomeViewController);

        cov_14ydsvtniq.f[0]++;
        cov_14ydsvtniq.s[0]++;

        this.newUser = UsersTable.$build();
        cov_14ydsvtniq.s[1]++;
        this.AuthService = AuthService;
        cov_14ydsvtniq.s[2]++;
        this.$state = $state;
        cov_14ydsvtniq.s[3]++;
        this.$rootScope = $rootScope;
        cov_14ydsvtniq.s[4]++;
        this.eventCategories = EventCategoriesTable.$search({ page_size: 8 });
    }

    _createClass(HomeViewController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_14ydsvtniq.f[1]++;
            cov_14ydsvtniq.s[5]++;

            if (this.$rootScope.me) {
                cov_14ydsvtniq.b[0][0]++;
                cov_14ydsvtniq.s[6]++;

                this.$rootScope.me.$then(function (user) {
                    cov_14ydsvtniq.f[2]++;
                    cov_14ydsvtniq.s[7]++;

                    if (user.type == 'MARKETEER') {
                        cov_14ydsvtniq.b[1][0]++;
                        cov_14ydsvtniq.s[8]++;

                        _this.$state.go('marketeers.membersList');
                    } else {
                            cov_14ydsvtniq.b[1][1]++;
                            cov_14ydsvtniq.s[9]++;
                            if (user.type == 'ACCOUNTANT') {
                                cov_14ydsvtniq.b[2][0]++;
                                cov_14ydsvtniq.s[10]++;

                                _this.$state.go('accountants.membersList');
                            } else {
                                cov_14ydsvtniq.b[2][1]++;
                                cov_14ydsvtniq.s[11]++;

                                _this.$state.go('amigos.dashboard');
                            }
                        }
                });
            } else {
                cov_14ydsvtniq.b[0][1]++;
            }
        }
    }, {
        key: 'selectType',
        value: function selectType(type) {
            cov_14ydsvtniq.f[3]++;
            cov_14ydsvtniq.s[12]++;

            this.type = type;
            cov_14ydsvtniq.s[13]++;
            this._selectCategory();
        }
    }, {
        key: 'selectPlan',
        value: function selectPlan(plan) {
            cov_14ydsvtniq.f[4]++;
            cov_14ydsvtniq.s[14]++;

            this.plan = plan;
            cov_14ydsvtniq.s[15]++;
            this._selectCategory();
        }
    }, {
        key: '_selectCategory',
        value: function _selectCategory() {
            cov_14ydsvtniq.f[5]++;
            cov_14ydsvtniq.s[16]++;

            if ((cov_14ydsvtniq.b[4][0]++, !this.plan) || (cov_14ydsvtniq.b[4][1]++, !this.type)) {
                cov_14ydsvtniq.b[3][0]++;
                cov_14ydsvtniq.s[17]++;

                this.newUser.category = null;
                cov_14ydsvtniq.s[18]++;
                return;
            } else {
                cov_14ydsvtniq.b[3][1]++;
            }
        }
    }, {
        key: '_getCategoryById',
        value: function _getCategoryById(id) {
            cov_14ydsvtniq.f[6]++;

            var finalCategory = (cov_14ydsvtniq.s[19]++, null);
            cov_14ydsvtniq.s[20]++;
            this.categories.forEach(function (category) {
                cov_14ydsvtniq.f[7]++;
                cov_14ydsvtniq.s[21]++;

                if (category.id == id) {
                    cov_14ydsvtniq.b[5][0]++;
                    cov_14ydsvtniq.s[22]++;

                    finalCategory = category;
                } else {
                    cov_14ydsvtniq.b[5][1]++;
                }
            });

            cov_14ydsvtniq.s[23]++;
            return finalCategory;
        }
    }, {
        key: 'saveUser',
        value: function saveUser() {
            var _this2 = this;

            cov_14ydsvtniq.f[8]++;
            cov_14ydsvtniq.s[24]++;

            if (!this.newUser.acceptTerms) {
                cov_14ydsvtniq.b[6][0]++;
                cov_14ydsvtniq.s[25]++;

                this.newUser.$response = {
                    status: 400,
                    data: {
                        acceptTerms: ["Para prosseguir tem que aceitar os termos e condições."]
                    }
                };
                cov_14ydsvtniq.s[26]++;
                return;
            } else {
                cov_14ydsvtniq.b[6][1]++;
            }

            cov_14ydsvtniq.s[27]++;
            if ((cov_14ydsvtniq.b[8][0]++, this.newUser.nif) && (cov_14ydsvtniq.b[8][1]++, this.newUser.nif[0] != '1') && (cov_14ydsvtniq.b[8][2]++, this.newUser.nif[0] != '2')) {
                cov_14ydsvtniq.b[7][0]++;
                cov_14ydsvtniq.s[28]++;

                this.newUser.$response = {
                    status: 400,
                    data: {
                        nif: ["Nº contribuinte inválido. Registo online apenas disponível para pessoas singulares."]
                    }
                };
                cov_14ydsvtniq.s[29]++;
                return;
            } else {
                cov_14ydsvtniq.b[7][1]++;
            }

            cov_14ydsvtniq.s[30]++;
            this.newUser.$save().$then(function (user) {
                cov_14ydsvtniq.f[9]++;
                cov_14ydsvtniq.s[31]++;

                _this2.registered = true;
            }, function () {
                cov_14ydsvtniq.f[10]++;
            });
        }
    }, {
        key: 'login',
        value: function login() {
            var _this3 = this;

            cov_14ydsvtniq.f[11]++;
            cov_14ydsvtniq.s[32]++;

            this.loginRequest = this.AuthService.login(this.login.email, this.login.password);

            cov_14ydsvtniq.s[33]++;
            this.loginRequest.then(function () {
                cov_14ydsvtniq.f[12]++;
                cov_14ydsvtniq.s[34]++;

                _this3.$state.go('amigos.dashboard');
            });
        }
    }]);

    return HomeViewController;
}();

cov_14ydsvtniq.s[35]++;


angular.module('app').component('homeView', {
    templateUrl: 'app/components/homeView/homeView.html',
    controller: HomeViewController,
    bindings: {
        categories: '<'
    }
});