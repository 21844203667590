'use strict';

var cov_hmjg8newb = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/paymentDetailsView/paymentDetailsView.js',
        hash = '3cd0429c01998143355f00a5a8b37297dcd825b0',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/paymentDetailsView/paymentDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 50
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 9
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 69
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 48
                },
                end: {
                    line: 14,
                    column: 68
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 39
                }
            },
            '7': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 71
                }
            },
            '8': {
                start: {
                    line: 20,
                    column: 48
                },
                end: {
                    line: 20,
                    column: 70
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 0
                },
                end: {
                    line: 36,
                    column: 45
                }
            },
            '10': {
                start: {
                    line: 39,
                    column: 4
                },
                end: {
                    line: 39,
                    column: 20
                }
            },
            '11': {
                start: {
                    line: 42,
                    column: 23
                },
                end: {
                    line: 42,
                    column: 53
                }
            },
            '12': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 59,
                    column: 4
                },
                end: {
                    line: 59,
                    column: 29
                }
            },
            '14': {
                start: {
                    line: 60,
                    column: 4
                },
                end: {
                    line: 62,
                    column: 5
                }
            },
            '15': {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 27
                }
            },
            '16': {
                start: {
                    line: 64,
                    column: 4
                },
                end: {
                    line: 67,
                    column: 5
                }
            },
            '17': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 50
                }
            },
            '18': {
                start: {
                    line: 66,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 44
                }
            },
            '19': {
                start: {
                    line: 69,
                    column: 4
                },
                end: {
                    line: 72,
                    column: 5
                }
            },
            '20': {
                start: {
                    line: 70,
                    column: 8
                },
                end: {
                    line: 70,
                    column: 50
                }
            },
            '21': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 40
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 46
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 17
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 13,
                        column: 17
                    },
                    end: {
                        line: 13,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 23
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                },
                line: 13
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 14,
                        column: 42
                    },
                    end: {
                        line: 14,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 48
                    },
                    end: {
                        line: 14,
                        column: 68
                    }
                },
                line: 14
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 20
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 18
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 20,
                        column: 35
                    },
                    end: {
                        line: 20,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 48
                    },
                    end: {
                        line: 20,
                        column: 70
                    }
                },
                line: 20
            },
            '6': {
                name: 'Service',
                decl: {
                    start: {
                        line: 38,
                        column: 9
                    },
                    end: {
                        line: 38,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 28
                    },
                    end: {
                        line: 56,
                        column: 1
                    }
                },
                line: 38
            },
            '7': {
                name: 'show',
                decl: {
                    start: {
                        line: 41,
                        column: 13
                    },
                    end: {
                        line: 41,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 27
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 41
            },
            '8': {
                name: 'DialogController',
                decl: {
                    start: {
                        line: 58,
                        column: 9
                    },
                    end: {
                        line: 58,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 64
                    },
                    end: {
                        line: 73,
                        column: 1
                    }
                },
                line: 58
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 60,
                        column: 25
                    },
                    end: {
                        line: 60,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 37
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 60
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 64,
                        column: 29
                    },
                    end: {
                        line: 64,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 41
                    },
                    end: {
                        line: 67,
                        column: 5
                    }
                },
                line: 64
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 69,
                        column: 27
                    },
                    end: {
                        line: 69,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 39
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                line: 69
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PaymentDetailsViewController = function () {
    function PaymentDetailsViewController($mdDialog, PaymentsCashTable) {
        _classCallCheck(this, PaymentDetailsViewController);

        cov_hmjg8newb.f[0]++;
        cov_hmjg8newb.s[0]++;

        this.$mdDialog = $mdDialog;
        cov_hmjg8newb.s[1]++;
        this.PaymentsCashTable = PaymentsCashTable;
        cov_hmjg8newb.s[2]++;
        this.paymentStates = {
            CREATED: 'Em Rascunho', IN_PAYMENT: 'Pendente', PAID: 'Pago', CANCELED: 'Cancelado', INVALID: 'Inválido'
        };
    }

    _createClass(PaymentDetailsViewController, [{
        key: 'markAsPaid',
        value: function markAsPaid() {
            var _this = this;

            cov_hmjg8newb.f[1]++;
            cov_hmjg8newb.s[3]++;

            this.PaymentsCashTable.$create({
                order: this.payment.id
            }).$then(function () {
                cov_hmjg8newb.f[2]++;
                cov_hmjg8newb.s[4]++;

                _this.payment.$refresh().$then(function () {
                    cov_hmjg8newb.f[3]++;
                    cov_hmjg8newb.s[5]++;
                    return _this.onPaymentPaid();
                });
            });
        }
    }, {
        key: 'cancelPayment',
        value: function cancelPayment() {
            var _this2 = this;

            cov_hmjg8newb.f[4]++;
            cov_hmjg8newb.s[6]++;

            this.payment.state = 'CANCELED';
            cov_hmjg8newb.s[7]++;
            this.payment.$save().$then(function (payment) {
                cov_hmjg8newb.f[5]++;
                cov_hmjg8newb.s[8]++;
                return _this2.onCancelPayment();
            });
        }
    }]);

    return PaymentDetailsViewController;
}();

cov_hmjg8newb.s[9]++;


angular.module('app').component('paymentDetailsView', {
    templateUrl: 'app/components/paymentDetailsView/paymentDetailsView.html',
    controller: PaymentDetailsViewController,
    bindings: {
        payment: '<',
        onCancelPayment: '&',
        onPaymentPaid: '&',
        onClose: '&'
    }
}).service('PaymentDetailsModal', Service);

function Service($mdDialog) {
    cov_hmjg8newb.f[6]++;
    cov_hmjg8newb.s[10]++;

    this.show = show;

    function show(payment) {
        cov_hmjg8newb.f[7]++;

        var parentEl = (cov_hmjg8newb.s[11]++, angular.element(document.body));
        cov_hmjg8newb.s[12]++;
        return $mdDialog.show({
            parent: parentEl,
            template: '<md-dialog aria-label="List dialog" style="min-width: 500px">' + '  <md-dialog-content>' + '    <payment-details-view payment="payment" on-cancel-payment="onCancelPayment()" on-payment-paid="onPaymentPaid()" on-close="closeDialog()"/>' + '  </md-dialog-content>' + '</md-dialog>',
            locals: { payment: payment },
            controller: DialogController,
            clickOutsideToClose: true
        });
    }
}

function DialogController($scope, $mdDialog, payment, $mdToast) {
    cov_hmjg8newb.f[8]++;
    cov_hmjg8newb.s[13]++;

    $scope.payment = payment;
    cov_hmjg8newb.s[14]++;
    $scope.closeDialog = function () {
        cov_hmjg8newb.f[9]++;
        cov_hmjg8newb.s[15]++;

        $mdDialog.cancel();
    };

    cov_hmjg8newb.s[16]++;
    $scope.onCancelPayment = function () {
        cov_hmjg8newb.f[10]++;
        cov_hmjg8newb.s[17]++;

        $mdToast.showSimple('Pagamento cancelado');
        cov_hmjg8newb.s[18]++;
        $mdDialog.hide({ action: 'CANCELED' });
    };

    cov_hmjg8newb.s[19]++;
    $scope.onPaymentPaid = function () {
        cov_hmjg8newb.f[11]++;
        cov_hmjg8newb.s[20]++;

        $mdToast.showSimple('Pagamento efectuado');
        cov_hmjg8newb.s[21]++;
        $mdDialog.hide({ action: 'PAID' });
    };
}