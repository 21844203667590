'use strict';

var cov_1zjxi2esb4 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/auth/auth.forgot.controller.js',
        hash = 'b9d8adb39eb0cf792fe69d5aa11d8981373320e3',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/auth/auth.forgot.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 35,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 66
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 17
                },
                end: {
                    line: 10,
                    column: 21
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 30
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 19
                }
            },
            '6': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 15
                }
            },
            '7': {
                start: {
                    line: 29,
                    column: 16
                },
                end: {
                    line: 29,
                    column: 32
                }
            },
            '8': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 35,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'AuthForgotController',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 54
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 18,
                        column: 17
                    },
                    end: {
                        line: 18,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 28
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 18
            },
            '3': {
                name: 'requestChange',
                decl: {
                    start: {
                        line: 21,
                        column: 17
                    },
                    end: {
                        line: 21,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 33
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                line: 21
            },
            '4': {
                name: 'successCallback',
                decl: {
                    start: {
                        line: 28,
                        column: 29
                    },
                    end: {
                        line: 28,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 55
                    },
                    end: {
                        line: 30,
                        column: 13
                    }
                },
                line: 28
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 30,
                        column: 15
                    },
                    end: {
                        line: 30,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 35
                    },
                    end: {
                        line: 32,
                        column: 13
                    }
                },
                line: 30
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1zjxi2esb4.s[0]++;
(function () {
    'use strict';

    cov_1zjxi2esb4.f[0]++;
    cov_1zjxi2esb4.s[1]++;
    angular.module('app').controller('AuthForgotController', AuthForgotController);

    /* @ngInject */
    function AuthForgotController($http, AppSettings) {
        cov_1zjxi2esb4.f[1]++;

        var vm = (cov_1zjxi2esb4.s[2]++, this);

        cov_1zjxi2esb4.s[3]++;
        vm.requestStatus = {};

        cov_1zjxi2esb4.s[4]++;
        vm.requestChange = requestChange;

        cov_1zjxi2esb4.s[5]++;
        activate();

        function activate() {
            cov_1zjxi2esb4.f[2]++;
        }

        function requestChange() {
            cov_1zjxi2esb4.f[3]++;
            cov_1zjxi2esb4.s[6]++;

            vm.requestStatus = $http({
                method: 'POST',
                url: AppSettings.apiUrl + '/users/reset_password/',
                data: {
                    email: vm.email
                }
            }).then(function successCallback(response) {
                cov_1zjxi2esb4.f[4]++;
                cov_1zjxi2esb4.s[7]++;

                return response;
            }, function (response) {
                cov_1zjxi2esb4.f[5]++;
                cov_1zjxi2esb4.s[8]++;

                return response;
            });
        }
    }
})();