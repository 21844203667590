'use strict';

var cov_s09nl1cq8 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/paginator/paginator.js',
        hash = 'e85fc798b888b5f3411adb770d881f609b062cce',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/paginator/paginator.js',
        statementMap: {
            '0': {
                start: {
                    line: 6,
                    column: 23
                },
                end: {
                    line: 6,
                    column: 63
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 31
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 38
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 20
                },
                end: {
                    line: 13,
                    column: 45
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 37
                }
            },
            '7': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 35
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 0
                },
                end: {
                    line: 31,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 5,
                        column: 4
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 5,
                        column: 23
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 5
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 19
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 17,
                        column: 4
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 19
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 17
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 12,
                        column: 38
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 12,
                        column: 38
                    }
                }, {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 12,
                        column: 38
                    }
                }],
                line: 11
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PaginatorController = function () {
    function PaginatorController() {
        _classCallCheck(this, PaginatorController);

        cov_s09nl1cq8.f[0]++;
    }

    _createClass(PaginatorController, [{
        key: 'goToPreviousPage',
        value: function goToPreviousPage() {
            cov_s09nl1cq8.f[1]++;

            var previous = (cov_s09nl1cq8.s[0]++, Math.max(1, this.collection.$page - 1));
            cov_s09nl1cq8.s[1]++;
            this.goToPage(previous);
        }
    }, {
        key: 'goToNextPage',
        value: function goToNextPage() {
            cov_s09nl1cq8.f[2]++;
            cov_s09nl1cq8.s[2]++;

            if (!this.collection.$page) {
                    cov_s09nl1cq8.b[0][0]++;
                    cov_s09nl1cq8.s[3]++;

                    this.collection.$page = 0;
                } else {
                cov_s09nl1cq8.b[0][1]++;
            }var next = (cov_s09nl1cq8.s[4]++, this.collection.$page + 1);
            cov_s09nl1cq8.s[5]++;
            this.goToPage(next);
        }
    }, {
        key: 'goToPage',
        value: function goToPage(page) {
            cov_s09nl1cq8.f[3]++;
            cov_s09nl1cq8.s[6]++;

            this.collection.$page = page;
            cov_s09nl1cq8.s[7]++;
            this.collection.$refresh();
        }
    }]);

    return PaginatorController;
}();

cov_s09nl1cq8.s[8]++;


angular.module('app').component('paginator', {
    templateUrl: 'app/components/paginator/paginator.html',
    controller: PaginatorController,
    bindings: {
        collection: '<'
    }
});