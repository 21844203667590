'use strict';

var cov_2bwr2yui7c = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/shared/js/media/media.service.js',
        hash = '67e1e1d1234631e8a0549c9324ecd79b725507c9',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/shared/js/media/media.service.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 36,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 42
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 74
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 29,
                    column: 19
                }
            },
            '6': {
                start: {
                    line: 22,
                    column: 20
                },
                end: {
                    line: 22,
                    column: 42
                }
            },
            '7': {
                start: {
                    line: 24,
                    column: 20
                },
                end: {
                    line: 24,
                    column: 71
                }
            },
            '8': {
                start: {
                    line: 25,
                    column: 20
                },
                end: {
                    line: 25,
                    column: 37
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 45
                },
                end: {
                    line: 27,
                    column: 85
                }
            },
            '10': {
                start: {
                    line: 28,
                    column: 20
                },
                end: {
                    line: 28,
                    column: 90
                }
            },
            '11': {
                start: {
                    line: 31,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 60
                }
            },
            '12': {
                start: {
                    line: 32,
                    column: 16
                },
                end: {
                    line: 32,
                    column: 33
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 36,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Service',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 52
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'upload',
                decl: {
                    start: {
                        line: 12,
                        column: 17
                    },
                    end: {
                        line: 12,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 30
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 21,
                        column: 24
                    },
                    end: {
                        line: 21,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 40
                    },
                    end: {
                        line: 23,
                        column: 17
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 23,
                        column: 19
                    },
                    end: {
                        line: 23,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 31
                    },
                    end: {
                        line: 26,
                        column: 17
                    }
                },
                line: 23
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 26,
                        column: 19
                    },
                    end: {
                        line: 26,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 34
                    },
                    end: {
                        line: 29,
                        column: 17
                    }
                },
                line: 26
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 33,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 33,
                        column: 13
                    }
                }, {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 33,
                        column: 13
                    }
                }],
                line: 15
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2bwr2yui7c.s[0]++;
(function () {
    'use strict';

    cov_2bwr2yui7c.f[0]++;
    cov_2bwr2yui7c.s[1]++;
    angular.module('app').service('MediaService', Service);

    /* @ngInject */
    function Service(Upload, AppSettings, $mdToast) {
        cov_2bwr2yui7c.f[1]++;
        cov_2bwr2yui7c.s[2]++;

        this.upload = upload;

        function upload(file) {
            cov_2bwr2yui7c.f[2]++;
            cov_2bwr2yui7c.s[3]++;

            $mdToast.show($mdToast.simple().textContent('Uploading ...'));

            cov_2bwr2yui7c.s[4]++;
            if (file) {
                cov_2bwr2yui7c.b[0][0]++;
                cov_2bwr2yui7c.s[5]++;

                return Upload.upload({
                    url: AppSettings.apiUrl + '/media/',
                    data: {
                        'file': file
                    }
                }).then(function (data) {
                    cov_2bwr2yui7c.f[3]++;
                    cov_2bwr2yui7c.s[6]++;

                    return data.data.file;
                }, function () {
                    cov_2bwr2yui7c.f[4]++;
                    cov_2bwr2yui7c.s[7]++;

                    $mdToast.updateTextContent('An error has ocurred');
                    cov_2bwr2yui7c.s[8]++;
                    return undefined;
                }, function (evt) {
                    cov_2bwr2yui7c.f[5]++;

                    var progressPercentage = (cov_2bwr2yui7c.s[9]++, parseInt(100.0 * evt.loaded / evt.total));
                    cov_2bwr2yui7c.s[10]++;
                    $mdToast.updateTextContent('Uploading (' + progressPercentage + '%)');
                });
            } else {
                cov_2bwr2yui7c.b[0][1]++;
                cov_2bwr2yui7c.s[11]++;

                $mdToast.updateTextContent('Invalid file!');
                cov_2bwr2yui7c.s[12]++;
                return undefined;
            }
        }
    }
})();