'use strict';

var cov_1g4gw1ot62 = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/paymentStateBadge/paymentStateBadge.js',
        hash = 'e54b6e24d2751b3397e0cadb23e8a67bda995a0f',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/paymentStateBadge/paymentStateBadge.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 9
                }
            },
            '1': {
                start: {
                    line: 9,
                    column: 0
                },
                end: {
                    line: 17,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 2
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PaymentStateBadgeController = function PaymentStateBadgeController() {
    _classCallCheck(this, PaymentStateBadgeController);

    cov_1g4gw1ot62.f[0]++;
    cov_1g4gw1ot62.s[0]++;

    this.paymentStates = {
        CREATED: 'Em Rascunho', IN_PAYMENT: 'Pendente', PAID: 'Pago', CANCELED: 'Cancelado', INVALID: 'Inválido'
    };
};

cov_1g4gw1ot62.s[1]++;


angular.module('app').component('paymentStateBadge', {
    templateUrl: 'app/components/paymentStateBadge/paymentStateBadge.html',
    controller: PaymentStateBadgeController,
    bindings: {
        state: '<'
    }
});