'use strict';

var cov_1hmflywjfa = function () {
    var path = '/home/lobo/repos/coliseu-app/src/app/components/editQuotaPriceView/editQuotaPriceView.js',
        hash = '71c76bfe70aaa2beacee107bed003bbd240bd35e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/app/components/editQuotaPriceView/editQuotaPriceView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 55
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 52
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 44
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 43
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 64
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 77
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 0
                },
                end: {
                    line: 31,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 53
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 11
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 15,
                        column: 36
                    },
                    end: {
                        line: 15,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 47
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 15
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EditQuotaPriceViewController = function () {
    function EditQuotaPriceViewController(UserTypePricesTable, UserTypesTable) {
        _classCallCheck(this, EditQuotaPriceViewController);

        cov_1hmflywjfa.f[0]++;
        cov_1hmflywjfa.s[0]++;

        this.UserTypePricesTable = UserTypePricesTable;
        cov_1hmflywjfa.s[1]++;
        this.newPrice = UserTypePricesTable.$build();

        cov_1hmflywjfa.s[2]++;
        this.UserTypesTable = UserTypesTable;
    }

    _createClass(EditQuotaPriceViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1hmflywjfa.f[1]++;
            cov_1hmflywjfa.s[3]++;

            this.newPrice.userType = this.type;
            cov_1hmflywjfa.s[4]++;
            this.userTypeName = this.UserTypesTable.$find(this.type);
        }
    }, {
        key: 'save',
        value: function save() {
            var _this = this;

            cov_1hmflywjfa.f[2]++;
            cov_1hmflywjfa.s[5]++;

            this.newPrice.$save().$then(function (price) {
                cov_1hmflywjfa.f[3]++;
                cov_1hmflywjfa.s[6]++;

                _this.onChange({ price: price });
                cov_1hmflywjfa.s[7]++;
                _this.newPrice = UserTypePricesTable.$build({ userType: _this.type });
            });
        }
    }]);

    return EditQuotaPriceViewController;
}();

cov_1hmflywjfa.s[8]++;


angular.module('app').component('editQuotaPriceView', {
    templateUrl: 'app/components/editQuotaPriceView/editQuotaPriceView.html',
    controller: EditQuotaPriceViewController,
    bindings: {
        type: '<',
        onChange: '&'
    }
});