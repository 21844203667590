'use strict';

var cov_zy8jmpdqc = function () {
    var path = '/home/lobo/repos/coliseu-app/src/index.js',
        hash = '9d65d52df15340007d424b990b0e31f4842ead95',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/coliseu-app/src/index.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 33,
                    column: 19
                }
            },
            '1': {
                start: {
                    line: 38,
                    column: 4
                },
                end: {
                    line: 38,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 41,
                    column: 4
                },
                end: {
                    line: 43,
                    column: 6
                }
            },
            '3': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 41
                }
            },
            '4': {
                start: {
                    line: 45,
                    column: 4
                },
                end: {
                    line: 47,
                    column: 6
                }
            },
            '5': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 42
                }
            },
            '6': {
                start: {
                    line: 49,
                    column: 4
                },
                end: {
                    line: 51,
                    column: 5
                }
            },
            '7': {
                start: {
                    line: 50,
                    column: 1
                },
                end: {
                    line: 50,
                    column: 83
                }
            },
            '8': {
                start: {
                    line: 53,
                    column: 4
                },
                end: {
                    line: 53,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 37,
                        column: 9
                    },
                    end: {
                        line: 37,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 37,
                        column: 76
                    },
                    end: {
                        line: 54,
                        column: 1
                    }
                },
                line: 37
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 41,
                        column: 29
                    },
                    end: {
                        line: 41,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 41
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                line: 41
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 45,
                        column: 30
                    },
                    end: {
                        line: 45,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 42
                    },
                    end: {
                        line: 47,
                        column: 5
                    }
                },
                line: 45
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 49,
                        column: 32
                    },
                    end: {
                        line: 49,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 44
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                line: 49
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_zy8jmpdqc.s[0]++;
angular.module('app', ['ui.router', 'restmod', 'restmod.styles.drfPaged', 'ngMaterial', 'satellizer', 'ngFileUpload', 'cgBusy', 'angularMoment', 'angular-loading-bar', 'timer', 'ui.mask', 'angular.filter', 'mdPickers', 'ui.tinymce', 'angular-click-outside', 'duScroll', 'app.notfound']).constant('moment', moment).constant('AppSettings', {
    appInProduction: false,
    appUrl: 'https://coliseu.soloweb.pt',
    apiUrl: 'https://api.coliseu.soloweb.pt',
    siteUrl: 'https://site.coliseu.soloweb.pt',
    mediaUrl: 'https://api.coliseu.soloweb.pt/static/media'
}).value('cgBusyDefaults', {
    message: '',
    templateUrl: 'app/shared/html/cgbusy/cgbusy.template.html'
}).run(runBlock);

/** @ngInject */
function runBlock($rootScope, AppSettings, $mdSidenav, ConfigurationsTable) {
    cov_zy8jmpdqc.f[0]++;
    cov_zy8jmpdqc.s[1]++;

    $rootScope.AppSettings = AppSettings;

    cov_zy8jmpdqc.s[2]++;
    $rootScope.openSidebar = function () {
        cov_zy8jmpdqc.f[1]++;
        cov_zy8jmpdqc.s[3]++;

        return $mdSidenav('menu').open();
    };

    cov_zy8jmpdqc.s[4]++;
    $rootScope.closeSidebar = function () {
        cov_zy8jmpdqc.f[2]++;
        cov_zy8jmpdqc.s[5]++;

        return $mdSidenav('menu').close();
    };

    cov_zy8jmpdqc.s[6]++;
    $rootScope.initStaticData = function () {
        cov_zy8jmpdqc.f[3]++;
        cov_zy8jmpdqc.s[7]++;

        $rootScope.ENABLE_REPORTS_VIEW = ConfigurationsTable.$find('ENABLE_REPORTS_VIEW');
    };

    cov_zy8jmpdqc.s[8]++;
    $rootScope.initStaticData();
}