"use strict";

var cov_h1kbxt19b = function () {
    var path = "/home/lobo/repos/coliseu-app/src/app/components/newMemberView/newMemberView.js",
        hash = "c57e1bee8544eb7689a72f23ac991d371dfa97b3",
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/lobo/repos/coliseu-app/src/app/components/newMemberView/newMemberView.js",
        statementMap: {
            "0": {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 10
                }
            },
            "1": {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 28
                }
            },
            "2": {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 75
                }
            },
            "3": {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 10
                }
            },
            "4": {
                start: {
                    line: 15,
                    column: 30
                },
                end: {
                    line: 15,
                    column: 74
                }
            },
            "5": {
                start: {
                    line: 15,
                    column: 53
                },
                end: {
                    line: 15,
                    column: 73
                }
            },
            "6": {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 13
                }
            },
            "7": {
                start: {
                    line: 17,
                    column: 16
                },
                end: {
                    line: 17,
                    column: 55
                }
            },
            "8": {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 92
                }
            },
            "9": {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 95
                }
            },
            "10": {
                start: {
                    line: 24,
                    column: 45
                },
                end: {
                    line: 24,
                    column: 94
                }
            },
            "11": {
                start: {
                    line: 28,
                    column: 19
                },
                end: {
                    line: 28,
                    column: 21
                }
            },
            "12": {
                start: {
                    line: 29,
                    column: 23
                },
                end: {
                    line: 29,
                    column: 87
                }
            },
            "13": {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 81
                }
            },
            "14": {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 81
                }
            },
            "15": {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 20
                }
            },
            "16": {
                start: {
                    line: 38,
                    column: 0
                },
                end: {
                    line: 44,
                    column: 7
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 52
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 2
            },
            "1": {
                name: "(anonymous_1)",
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 10
            },
            "2": {
                name: "(anonymous_2)",
                decl: {
                    start: {
                        line: 14,
                        column: 29
                    },
                    end: {
                        line: 14,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 38
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 14
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 15,
                        column: 43
                    },
                    end: {
                        line: 15,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 53
                    },
                    end: {
                        line: 15,
                        column: 73
                    }
                },
                line: 15
            },
            "4": {
                name: "(anonymous_4)",
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 11
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                line: 22
            },
            "5": {
                name: "(anonymous_5)",
                decl: {
                    start: {
                        line: 24,
                        column: 37
                    },
                    end: {
                        line: 24,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 45
                    },
                    end: {
                        line: 24,
                        column: 94
                    }
                },
                line: 24
            },
            "6": {
                name: "(anonymous_6)",
                decl: {
                    start: {
                        line: 27,
                        column: 4
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 24
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 27
            }
        },
        branchMap: {
            "0": {
                loc: {
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                }, {
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                }],
                line: 16
            }
        },
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0
        },
        b: {
            "0": [0, 0]
        },
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NewMemberViewController = function () {
    function NewMemberViewController(UsersTable, $state, UserTypesTable) {
        _classCallCheck(this, NewMemberViewController);

        cov_h1kbxt19b.f[0]++;
        cov_h1kbxt19b.s[0]++;

        this.newMember = UsersTable.$build({
            admissionDate: new Date()
        });
        cov_h1kbxt19b.s[1]++;
        this.$state = $state;
        cov_h1kbxt19b.s[2]++;
        this.userTypes = UserTypesTable.$search({ is_accepting_users: true });
    }

    _createClass(NewMemberViewController, [{
        key: "$onInit",
        value: function $onInit() {
            var _this = this;

            cov_h1kbxt19b.f[1]++;
            cov_h1kbxt19b.s[3]++;

            /*
            Select the default user type if there is one
             */
            this.userTypes.$then(function (types) {
                cov_h1kbxt19b.f[2]++;

                var defaultType = (cov_h1kbxt19b.s[4]++, types.filter(function (item) {
                    cov_h1kbxt19b.f[3]++;
                    cov_h1kbxt19b.s[5]++;
                    return item.defaultUserType;
                }));
                cov_h1kbxt19b.s[6]++;
                if (defaultType[0]) {
                    cov_h1kbxt19b.b[0][0]++;
                    cov_h1kbxt19b.s[7]++;

                    _this.newMember.type = defaultType[0].id;
                } else {
                    cov_h1kbxt19b.b[0][1]++;
                }
            });
        }
    }, {
        key: "save",
        value: function save() {
            var _this2 = this;

            cov_h1kbxt19b.f[4]++;
            cov_h1kbxt19b.s[8]++;

            this.newMember.password = this.newMember.confirm_password = this._generatePassword();
            cov_h1kbxt19b.s[9]++;
            this.newMember.$save().$then(function (user) {
                cov_h1kbxt19b.f[5]++;
                cov_h1kbxt19b.s[10]++;
                return _this2.$state.go('^.member.details', { id: user.id });
            });
        }
    }, {
        key: "_generatePassword",
        value: function _generatePassword() {
            cov_h1kbxt19b.f[6]++;

            var text = (cov_h1kbxt19b.s[11]++, "");
            var possible = (cov_h1kbxt19b.s[12]++, "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789");

            cov_h1kbxt19b.s[13]++;
            for (var i = 0; i < 10; i++) {
                cov_h1kbxt19b.s[14]++;

                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }cov_h1kbxt19b.s[15]++;
            return text;
        }
    }]);

    return NewMemberViewController;
}();

cov_h1kbxt19b.s[16]++;


angular.module('app').component('newMemberView', {
    templateUrl: 'app/components/newMemberView/newMemberView.html',
    controller: NewMemberViewController,
    bindings: {}
});